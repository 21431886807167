:global {
    :local(.wrapper) {
        height: 100%;
        padding: 24px;
        display: flex;

        >.left {
            width: 260px;
            background-color: #ffffff;
            border-radius: 2px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .16);
        }

        >.right {
            margin-left: 12px;
            width: 0;
            flex-grow: 1;
            background-color: #ffffff;
            border-radius: 2px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .16);
            padding: 0 12px;

            >.header {
                padding: 12px 0;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    :local(.drawer) {
        height: 100%;
    }
}