:global {
    :local(.bigProductionTotal) {
        width: 768px;
        height: 496px;
        background-color: #091732;
        border-radius: 16px;
        padding: 32px;
        margin-top: 24px;

        .bigProductionTotal-title {
            font-size: 30px;
            line-height: 42px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fff;
            opacity: .8;
            box-sizing: border-box;
        }

        .bigProductionTotal-list {
            list-style: none;
            padding-left: 8px;
            color: #FFFFFF;
            font-size: 14px;
            font-family: Microsoft YaHei;

            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 36px;

                &>span {
                    text-align: right;
                    font-size: 25px;
                    line-height: 33px;
                    color: #fff;
                    &.thead {
                        font-size: 24px;
                        line-height: 33px;                        
                        opacity: 0.6;
                        margin-top: 20px;
                    } 
                }

                .name {                    
                    width: 170px;
                    text-align: left !important;
                    .energy {
                        display: inline-block;
                        width: 72px;
                        color: #23D5E0;
                        opacity: .8;
                        font-size: 24px
                    }

                    .type {
                        color: #fff;
                        opacity: 0.8;
                        font-size: 22px;
                        margin-left: 24px;
                    }
                }
            }
        }
    }
}
