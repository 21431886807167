.abnormal {
  width: 1940px;
  height: 376px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #727272;
    opacity: 1;
    margin-bottom: 33px;
  }
  ul {
    padding-left: 0px;
  }
  ul li {
    list-style: none;
    display: flex;
  }
}
.itemList {
  border-bottom: 1px solid #F1F1F1;
  padding-top: 12px;
  padding-bottom: 24px;
  box-sizing: border-box;
  display: flex;
}
.item-left {
  color: #3A3A3A;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  width: 240px;
}
.item-center {
  &-top {
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 27px;
    color: #3A3A3A;
    opacity: 1;
    margin-bottom: 16px;
  }
  &-center {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 24px;
    color: #727272;
    opacity: 1;
  }
  /*滚动条整体样式*/
  .abnormal::-webkit-scrollbar {
    width: 10px;
    height: 1px;
  }
  /*滚动条滑块*/
  .abnormal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #357CF7;
  }
  /*滚动条轨道*/
  .abnormal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    border-radius: 10px;
    background: #E9E9E9;
  }
}
