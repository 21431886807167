:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            margin-top: 12px;
            margin-bottom: 24px;
            margin-left: 24px;
            margin-right: 24px;
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left {
                width: 528px;
            }

            >.right {
                margin-left: 16px;
                width: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                >.top {
                    height: 304px;
                }

                >.bottom {
                    margin-top: 16px;
                    height: 0;
                    flex-grow: 1;
                }
            }
        }
    }
}