:global {
    :local(.wrapper) {
        height: 100%;
        background-color: rgba(8, 27, 91, 1);
        overflow-y: hidden;
    }

    :local(.content) {
        height: 100%;
        padding: 112px 48px 0 48px;

        >.top {
            display: flex;
            justify-content: center;

            >.title {
                min-width: 864px;
                height: 64px;
                padding: 0 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(270deg, rgba(1, 44, 101, 0) 0%, #012C65 48%, rgba(1, 44, 101, 0) 100%);
                font-size: 40px;
                line-height: 56px;
                color: rgba(63, 202, 241, 1);
            }
        }

        >.bottom {
            margin-top: 32px;
        }
    }
}