:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            overflow: hidden;
            position: relative;

            >.resize {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.content {
                position: relative;
                transform-origin: left top 0;
                // transform: scale(0.5);
                height: 100%;
            }
        }
    }
}