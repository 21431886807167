:global {
    :local(.wrapper) {
        position: relative;
    }

    :local(.file_wrapper) {
        padding: 4px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.45);

        >.title {
            padding: 0 8px;
            color: #1890ff;
            cursor: pointer;
        }

        >.delete {
            display: none;
        }

        &:hover {
            background-color: #f5f5f5;

            >.delete {
                display: block;
                // cursor: pointer;
            }
        }
    }
}