:global {
    :local(.wrapper) {
        height: 100%;
        overflow-y: auto;

        >.content {
            padding: 16px;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;

            >.item {
                height: 48px;
                background-color: rgba(245, 245, 245, 1);
                padding-left: 14px;
                padding-right: 48px;
                display: flex;
                align-items: center;

                >.left {
                    color: rgba(0, 0, 0, 1);
                    font-size: 14px;
                    line-height: 20px;
                    // height: 20px;
                    opacity: 0.65;
                }

                >.right {
                    margin-left: auto;
                    color: rgba(53, 124, 247, 1);
                    font-size: 30px;
                    line-height: 36px;
                    height: 36px;
                }

            }
        }
    }
}