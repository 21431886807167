:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #EDEEF1;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            padding: 0 24px 24px 24px;
            display: flex;

            >.left,
            >.mid {
                width: 0;
            }

            >.mid {
                margin-left: 12px;
            }

            >.left {
                flex-grow: 648;
            }

            >.mid {
                flex-grow: 742;
            }

        }
    }
}