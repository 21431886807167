:global {
    :local(.wrapper) {
        height: 100%;
        overflow-y: auto;

        >.content {
            padding: 16px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;

            >.item {
                height: 88px;
                background-color: rgba(245, 245, 245, 1);
                padding-left: 16px;

                >.top {
                    margin-top: 14px;
                    color: rgba(0, 0, 0, 1);
                    font-size: 14px;
                    line-height: 20px;
                    height: 20px;
                    opacity: 0.65;
                }

                >.bottom {
                    margin-top: 6px;
                    display: flex;
                    align-items: flex-end;
                    opacity: 0.85;

                    >.value {
                        color: rgba(53, 124, 247, 1);
                        text-decoration: underline;
                        font-size: 30px;
                        line-height: 36px;
                        height: 36px;
                        min-width: 50px;
                        user-select: none;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.7;
                        }
                    }

                    >.unit {
                        color: rgba(35, 35, 36, 1);
                        margin-left: 6px;
                        margin-bottom: 3px;
                        font-size: 12px;
                        line-height: 17px;
                        height: 17px;
                    }
                }
            }
        }
    }
}