:global {
    :local(.wrapper) {
        height: 44px;
        display: flex;
        align-items: center;

        >.right {
            margin-left: auto;
        }
    }
}