:global {
    :local(.bigEnergyTarget) {
        padding: 30px 40px 0;

        .bigEnergyTarget-header {
            display: flex;
            color: #A6B2C2;
            margin-bottom: 8px;

            &-right {
                width: 248px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #fff;
                opacity: 0.8;
            }
        }

        .bigEnergyTarget-item {
            display: flex;
            align-items: flex-end;
            margin-bottom: 20px;

            &-left {
                flex: 1;

                .left-top {
                    margin-bottom: 22px;

                    &-name {
                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 40px;
                        color: #23D5E0;
                        opacity: 0.8;
                        margin-bottom: 12px;
                        margin-right: 16px;
                    }

                    &-type {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 30px;
                        color: #fff;
                        opacity: 0.6;
                    }
                }

                .left-bottom {
                    white-space: nowrap;

                    &-number {
                        width: 120px;
                        font-size: 32px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        line-height: 32px;
                        color: #FFFFFF;
                        opacity: 1;
                        display: inline-block;
                    }

                    &-percent {
                        width: 150px;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 24px;
                        color: #fff;
                        opacity: 0.8;
                        margin-right: 8px;
                        display: inline-block;
                    }

                    &-comparison {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 24px;
                        color: #fff;
                        opacity: 0.8;
                    }
                }
            }
        }

        .triangle {
            display: inline-block;
            margin-bottom: 2px;
            margin-right: 5px;

            &.up {
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 12px solid#33C2A4;
            }

            &.down {
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 12px solid #FD6161;
            }

            &+span {
                text-indent: 10px;
            }
        }
    }
}
