:global {
    :local(.wrapper) {
        height: 100%;
        padding: 88px 0 40px 48px;

        >.content_wrapper {
            height: 100%;
            overflow-y: auto;

            >table {
                width: 2496px;
                font-size: 20px;
                line-height: 28px;
                color: rgba(255, 255, 255, 1);
                border-collapse: collapse;

                th,
                td {
                    text-align: center;
                    padding: 0;
                }

                >thead {
                    color: rgba(255, 255, 255, 0.85);

                    >tr {
                        height: 72px;
                        background-color: rgba(12, 73, 123, 1);

                        // >th {}
                    }
                }

                >tbody {
                    >tr {
                        height: 96px;

                        &:nth-child(2n+1) {
                            background-color: rgba(12, 73, 123, 0.5);
                        }

                        &:nth-child(2n) {
                            background-color: rgba(1, 44, 101, 0.5);
                        }
                    }
                }
            }
        }
    }
}