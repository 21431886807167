:global {
    :local(.wrapper) {
        height: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        position: relative;

        >.header {
            height: 56px;
            display: flex;
            align-items: center;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: rgba(225, 230, 240, 1);
            }

            >.colored_bar {
                width: 4px;
                height: 32px;
                background-color: rgba(0, 102, 255, 1);
                border-radius: 0px 100px 100px 0px;
            }

            >.title {
                margin-left: 32px;
                font-size: 16px;
                line-height: 16px;
                color: rgba(0, 102, 255, 1);
            }
        }

        >.content {
            height: 0;
            flex-grow: 1;
        }
    }
}