:global {
    :local(.DiagramGenWrapper) {
        display: flex;
        height: 100%;
        min-height: 400px;
        width: 100%;
        min-width: 1000px;

        .inputLabel {
            width: 60px;
            line-height: 30px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 3px;
            background-color: #1890ff;
            cursor: pointer;
            color: white;
        }

        .inputLabel:hover {
            background-color: #40a9ff;
        }

        .inputFile {
            display: none;
        }

        >.left {
            width: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            >.bottom {
                height: 0;
                flex-grow: 1;
            }
        }

        >.left,
        >.mid,
        >.right {
            box-sizing: border-box;
            border: 1px solid grey;
        }
    }
}