:global {
    :local(.PicEditPartWrapper) {
        height: 100%;
        padding: 10px;
        display: flex;

        .innerBox {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .imgWrapper {
                overflow: hidden;
                box-shadow: 0px 0px 0px 1px #000000;
                position: relative;

                .item {
                    display: block;
                    position: absolute; // border: 1px solid #000000;
                    cursor: pointer;
                    box-sizing: content-box;

                    .itemImg {
                        width: 100%;
                        display: block;
                    }

                    .line {
                        background-color: red;
                        position: absolute;
                    }

                    .left {
                        width: 1px;
                        height: 4000px;
                        top: -2000px;
                        left: 0;
                    }

                    .right {
                        width: 1px;
                        height: 4000px;
                        top: -2000px;
                        right: 0;
                    }

                    .top {
                        width: 4000px;
                        height: 1px;
                        left: -2000px;
                        top: 0;
                    }

                    .bottom {
                        width: 4000px;
                        height: 1px;
                        left: -2000px;
                        bottom: 0;
                    }

                    .hide {
                        display: none;
                    }
                }

                .item:hover {
                    box-shadow: 0px 0px 5px 0px blue;
                }

                .itemNum {
                    line-height: 100%;
                    min-width: 0px;
                    max-width: 100%;
                    // overflow: hidden;
                    text-align: right;
                    user-select: none;
                }
            }

            .loadingImg {
                opacity: 0.0;
            }
        }
    }
}