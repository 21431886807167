:global {
    :local(.bigEnergyTotal) {
        width: 100%;
        height: 416px;

        .bigEnergyTotal-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #A6B2C2;
            opacity: 1;
            padding: 16px 0px 16px 16px;
            box-sizing: border-box;
        }

        .bigEnergyTotal-list {
            padding: 12px 40px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            &-item {
                width: 332px;
                height: 184px;
                background: #081E3C;
                border: 1px solid #2A70B6;
                opacity: 1;
                border-radius: 10px;

                .item-top {
                    margin-bottom: 24px;
                    padding-left: 16px;
                    padding-top: 16px;

                    &-name {
                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 40px;
                        color: #23D5E0;
                        opacity: 0.8;
                        margin-right: 8px;
                    }

                    &-type {
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 30px;
                        color: #fff;
                        opacity: 0.6;
                    }
                }

                .item-center {
                    padding-left: 24px;
                    margin-bottom: 32px;

                    &-week {
                        width: 32px;
                        height: 32px;
                        background: #40B99B;
                        border-radius: 50%;
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #13191F;
                        opacity: 0.8;
                        display: inline-block;
                        text-align: center;
                        line-height: 32px;
                        margin-right: 16px;
                        vertical-align: middle;
                    }

                    &-number {
                        font-size: 32px;
                        line-height: 32px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        opacity: 1;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .item-bottom {
                    padding-right: 16px;
                    // text-align: right;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    &-number {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                        opacity: .8;
                    }

                    &-time {
                        // font-size: 24px;
                        // font-family: Microsoft YaHei;
                        // font-weight: 400;
                        // line-height: 24px;
                        // color: #fff;
                        // opacity: 0.6;

                        width: 32px;
                        height: 32px;
                        background: #40B99B;
                        border-radius: 50%;
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #13191F;
                        opacity: 0.8;
                        display: inline-block;
                        text-align: center;
                        line-height: 32px;
                        margin-right: 16px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
