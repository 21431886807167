:global {
    :local(.bigYieldTarget) {
        width: 976px;
        height: 367px;
        padding: 32px 48px 32px 32px;
        background-color: #091732;
        border-radius: 16px;

        .bigYieldTarget-header {
            display: flex;
            align-items: flex-end;
            margin-bottom: 8px;

            &-left {
                width: 0;
                flex-grow: 1;
                margin-right: 200px;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 42px;
                color: #fff;
                opacity: 0.8;
            }

            &-right {
                width: 248px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 24px;
                color: #fff;
                opacity: 0.8;
            }
        }

        .bigYieldTarget-item {
            display: flex;
            align-items: flex-end;

            &-left {
                flex: 1;

                .item-name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 33px;
                    color: #23D5E0;
                    opacity: 0.8;
                    margin-bottom: 14px;
                }

                .item-progress {
                    width: 408px;
                    height: 16px;
                    background: #1E3459;
                    opacity: 1;
                    border-radius: 8px;
                    position: relative;
                    display: flex;
                    align-items: center;

                    &-proNum {
                        width: 67%;
                        height: 100%;
                        background: #19AAD5;
                        border-radius: 8px;
                    }

                    &-percent {
                        position: absolute;
                        right: -40px;
                        font-size: 28px;
                        line-height: 28px;
                        color: #fff;
                        transform: translateX(100%);
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 29px;
            }
        }
    }
}
