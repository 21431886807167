:global {
    :local(.detailWrapper) {
        height: 100%;
        min-width: 1600px;
        overflow-y: auto;

        .page-mixed-title{
            font-size: 14px;
            font-weight: bold;
            color: #000;
        }
        .remark-box{
            margin-top: 2px;
            margin-left: 14px;
            color: #444;
            .remark-btn{
                color: #1890ff;
                margin-left: 4px;
                &:hover{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .detailWrapper-header {
            height: 60px;
            background: #FFFFFF;
            display: flex;
            justify-content: space-between;
            line-height: 60px;
            padding: 0px 16px;
            box-sizing: border-box;
            margin-bottom: 12px;

            &-left {
                display: flex;
            }

            .parent-bread {
                color: rgba(0, 0, 0, .45);
            }

            .clickable-bread {
                cursor: pointer;

                &:hover {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }

        .clickable-text:hover {
            cursor: pointer;
            // font-weight: bold;
            color: #000;
            text-decoration: underline;
        }

        .detailWrapper-container {
            padding: 0px 20px;
            box-sizing: border-box;

            .wrapper {
                padding: 20px;
                box-sizing: border-box;
                background: #FFFFFF;
                border-radius: 8px;

                &-header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    &-left {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .vertical-divider {
                            height: 28px;
                            width: 1px;
                            background-color: #ddd;
                            margin-left: 18px;
                        }
                    }
                }

                &-table {
                    //display: flex;

                    .ant-table tr > td,
                    .ant-table tr > th {
                        padding-left: 3px;
                        padding-right: 3px;
                    }

                    .ant-table-row {
                        &:hover {
                            >td {
                                background-color: #eaeaea;
                            }
                        }
                    }

                    &-total {
                        padding: 20px 0px;
                        box-sizing: border-box;
                        border-top: 1px solid #cccccc;
                        border-bottom: 1px solid #cccccc;
                    }

                    &-title {
                        font-weight: bold;
                        margin-top: 20px;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}