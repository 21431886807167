:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        // justify-content: center;
        padding: 12px 0;

        >.content {
            width: 50%;
            height: 800px;
        }
    }
}