:global {
    :local(.exam_analysis_right_viewer_wrapper) {
        height: 100%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        //右侧视图

        .title1 {
            height: 48px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            // font-weight: 500;
            font-weight: bold;
            color: #357CF7;
            line-height: 14px;
            padding-left: 20px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: rgba(0, 0, 0, 1);
                opacity: 0.08;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 2px;
                background: rgba(24, 144, 255, 1);
            }
        }

        .cus_table_wrapper {
            padding: 24px;
            flex-grow: 1;
            height: 0;

            .ant-table-wrapper {
                height: 100%;

                .ant-spin-nested-loading {
                    height: 100%;

                    .ant-spin-container {
                        height: 100%;

                        .ant-table.ant-table-fixed-header {
                            height: 100%;

                            .ant-table-container {
                                height: 100%;
                                display: flex;
                                flex-direction: column;

                                .ant-table-header {
                                    flex-shrink: 0;
                                }
                            }
                        }
                    }
                }
            }

            // .ant-table-cell-scrollbar {
            //     opacity: 0;
            // }

            .ant-table-body {
                //hardcode

                //滚动条背景
                &::-webkit-scrollbar {
                    width: 10px;
                    background-color: rgba(0, 0, 0, 0);
                }

                // //滚动条前景
                &::-webkit-scrollbar-thumb {
                    background-color: #dadada;
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                    background-color: rgba(200, 200, 200, 0.1);
                }
            }

            .cus_table_wrapper_in_board_table {

                .th_block_wrapper {
                    display: inline-block;

                    .th_sort_wrapper {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        user-select: none;

                        .title {
                            height: 20px;
                            font-size: 14px;
                            // font-weight: 500;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 1);
                            line-height: 20px;
                        }

                        .sort_icons_wrapper {
                            margin-left: 5px;

                            .icon {
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-color: transparent;
                                border-width: 0;
                            }

                            .icon_up {
                                border-width: 0 4px 6px 4px;
                                border-color: transparent transparent rgba(216, 216, 216, 1) transparent;
                            }

                            .icon_down {
                                margin-top: 2px;
                                border-width: 6px 4px 0 4px;
                                border-color: rgba(216, 216, 216, 1) transparent transparent transparent;
                            }

                            .icon_up_selected {
                                border-color: transparent transparent rgba(24, 144, 255, 1) transparent;
                            }

                            .icon_down_selected {
                                border-color: rgba(24, 144, 255, 1) transparent transparent transparent;
                            }
                        }
                    }
                }

                th {
                    background-color: rgba(0, 0, 0, 0.03);
                }

                th.name_cell {
                    font-size: 16px;
                    // font-weight: 500;
                    font-weight: bold;
                    color: #357CF7;
                }

                tr.tr_dark_bck {
                    >td {
                        background: rgba(0, 0, 0, 0.02);
                    }
                }

                tr.expanded_factor_tr+tr {

                    //antd无法对expand节点赋className，只好用兄弟选择器
                    >td:first-child:not(.name_cell) {
                        padding: 0;
                    }
                }

                td:not(.name_cell) {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.64);
                }

                td.name_cell {
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 400;
                    color: #357CF7;
                }

                .score_method_text_wrapper {
                    display: inline-block;

                    .inner_block {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .cus_icon {
                            margin-left: 7px;
                            color: #357CF7;
                            font-size: 16px;
                        }
                    }
                }

                .chart_wrapper {
                    position: relative;
                    padding: 16px 0 10px;

                    .fold_icon {
                        position: absolute;
                        top: 0;
                        left: 49px;
                        width: 40px;
                        cursor: pointer;
                    }

                    .chart {
                        // height: 225px;
                        margin: 0 auto;
                    }
                }
            }
        }

    }
}