:global {
    :local(.MidWrapperPart) {
        height: 100%;
        width: 200px;
        overflow: auto;
        overflow-x: hidden;
        flex-shrink: 0;

        .ListItem {
            cursor: pointer;
            padding-left: 5px;
            padding-right: 5px;

            .itemWrapper {
                flex-grow: 1;

                .displayWrapper {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .itemIndex {
                        word-break: break-all;
                    }

                    >img {
                        width: 50px;
                        height: 50px;
                        display: block;
                        box-sizing: border-box;
                        border: 1px solid grey;
                        margin-left: 3px;
                    }
                }

                .manipulationWrapper {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .ListItem:hover {
            background-color: azure;
        }

        .selected {
            background-color: antiquewhite;
        }

        .selected:hover {
            background-color: antiquewhite;
        }

        .footerWrapper {
            >div {
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-bottom: 5px;

                .detailBtn {
                    padding: 0 6px;

                    >span {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}