:global {
  :local(.wrapper) {
    width: 100%;
    background: rgb(230, 237, 255);
    height: 100%;
    display: flex;
    overflow: auto;
    position: relative;

    > .body-right {
      width: 0;
      flex-grow: 1;
      background: #fff;
      padding: 10px;

      .elec-table-header {
        background: #3e5a94;
        color: #fff;
        font-size: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .elec-tabel-cell {
        font-weight: normal;
        background: #fff;
      }

      .elec-tabel-title {
        background: #f2f2f2;
      }

      .day-table {
        margin-top: 20px;
      }
      .body-title {
        padding: 10px;
        border-bottom: 1px solid #aaa;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .parent-menu {
        font-size: 20px;
        font-weight: bold;
      }

      .back-button {
        margin-right: 10px;
      }

      #StackedLine {
        height: 600px;
      }
      #percent {
        height: 100px;
      }
      #percent1 {
        height: 100px;
      }

      .pointer {
        cursor: pointer;
      }

      .unit {
        margin-left: 10px;
      }

      .min-table-cell {
        min-width: 48px;
        padding: 0 !important;
      }

      .no-tbody .ant-table-tbody {
        display: none !important;
      }

      .red {
        color: red;
      }

      .fz16 {
        font-size: 16px;
      } 
      .card-container {
        padding: 24px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        height: 110px;
        margin: 24px;
        border-radius: 5px;
        align-items: center;
        div {
          text-align: center;
           width: 100%;
          
           h3 {
            color: rgb(139, 142, 148);
            font-size: 16px;
            margin-bottom: 0px;
            line-height: 14px;
           }
           font-weight: 800;
           font-size: 16px;
           div.text {
            min-height: 52px;
           }
        }
      }

      .zebra {
        td {
          background: #f2f5fa;
        }
        
      }
    }
  }
}
