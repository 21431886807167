:global {
    :local(.eventTable) {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;

        .table-wrap {
            flex-grow: 1;
            padding: 27px 24px 0;
        }
    }
}