:global {
    :local(.eventView) {
        display: flex;
        flex-direction: column;
        padding: 0 24px 24px;
        height: 100%;
        background-color: #EDEEF1;

        .main-warpper {
            height: 0;
            flex-grow: 1;
            display: flex;
            justify-content: space-between;

            &-left {
                width: 260px;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
                margin-right: 12px;
            }

            &-right {
                width: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);

                .header-bar {
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    border-bottom: 1px solid #C9C9C9;
                    padding-right: 24px;
                    padding-left: 24px;

                    >.left {
                        margin-right: auto;
                    }
                }
                .table-wrap {
                    height: 0;
                    flex-grow: 1;
                    padding: 27px 24px 0;
                }
            }
        }
    }

    :local(.drawer) {
        height: 100%;
    }
}