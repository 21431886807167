:global {
    :local(.wrapper) {
        height: 100%;
        padding-left: 48px;

        >table {
            width: 1120px;
            table-layout: fixed;
            text-align: center;

            th,
            td {
                padding: 0;
            }

            >thead {
                font-size: 18px;
                line-height: 25px;
                color: rgba(77, 193, 215, 1);

                >tr {
                    height: 45px;

                    >th {
                        &.name {
                            width: 150px;
                        }

                        &.val {
                            width: 90px;
                        }

                        &.time {
                            width: 144px;
                        }

                        &.blank {
                            width: 32px;
                        }
                    }
                }
            }

            >tbody {
                >tr {
                    height: 56px;

                    &:nth-child(2n+1) {
                        >td {
                            background-color: rgba(12, 73, 123, 0.3);

                            &.name,
                            &:nth-child(5) {
                                border-radius: 2px 0 0 2px;
                            }

                            &.time,
                            &:last-child {
                                border-radius: 0 2px 2px 0;
                            }
                        }
                    }

                    >td {
                        &.name {
                            padding-left: 24px;
                            color: rgba(77, 193, 215, 1);
                            font-size: 20px;
                            line-height: 28px;
                        }

                        &.val {
                            color: rgba(255, 255, 255, 1);
                            text-align: right;
                            font-size: 24px;
                            line-height: 33px;
                        }

                        &.time {
                            padding-right: 23px;
                            text-align: right;
                            color: rgba(255, 255, 255, 1);
                            font-size: 18px;
                            line-height: 25px;
                        }

                        &.blank {
                            background-color: transparent;
                        }

                        &.value {
                            color: rgba(255, 255, 255, 1);
                            font-size: 20px;
                            line-height: 28px;

                            &.red {
                                color: rgba(244, 90, 102, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}