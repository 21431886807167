:global {
    :local(.history_data_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #333;
        background-color: #EDEEF1;

        .ant-select {
            .ant-select-selection {
                background-color: red !important;
            }
        }

        .ant-btn-primary,
        .ant-btn-primary:hover,
        .ant-btn-primary:focus,
        .ant-btn-background-ghost {
            background-color: #357cf7;
            color: #fff;
            border-color: #357cf7;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: #dadada;
            background-color: #fff;
        }

        .ant-select-arrow {
            color: #666;
        }

        .ant-radio-wrapper {
            color: #333;
        }

        .ant-select-selection-item {
            color: #666 !important
        }

        .ant-table-body,
        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 4px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color: #dadada, $alpha: 0.25);
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: rgba(200, 200, 200, 0.1);
            }
        }

        .history_data_header_wrapper {
            height: 70px;
            background-color: #f2f2f2;
            color: #333;
            // background: rgba(242, 242, 242, 1);
            // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.11);
            display: flex;
            align-items: center;
            padding: 0 24px;
            // margin: 24px;

            .divide_line {
                margin-left: 28px;
                width: 1px;
                height: 28px;
                background: #666;
                opacity: 0.18;
            }

            .picker {
                border-color: #dadada;
                background-color: #fff;
                border-radius: 2px !important;

                input {
                    color: #666;
                }

                input::placeholder,
                .ant-picker-separator {
                    color: #ccc;
                }

            }

            .opt_title {
                margin: 0 12px 0 0;
                font-size: 14px;
                font-weight: 400;
                // color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
            }
        }

        .history_data_content_wrapper {
            color: #333;
            height: 0;
            flex-grow: 1;
            // background: rgba(240, 242, 245, 1);
            overflow-y: auto;
            margin: 0 24px 24px 24px;
            background-color: #fff;
            padding: 0;
            box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.07);

            .ant-table-container {
                background-color: #fff;
            }

            .ant-table-wrapper {
                .ant-table-thead>tr>th {
                    background-color: #f5f5f5;
                    color: #666;
                    border: none;
                }

                input {
                    color: #333;
                }

                .ant-table-body {
                    background-color: #fff;

                    .ant-empty-description {
                        color: #ccc;
                    }

                    tr>td {
                        border-bottom: 1px solid #dfdfdf;
                    }

                    tr>td:hover {
                        background-color: #fff;
                    }
                }
            }

            .content_wrapper {
                height: 100%;
                // background: rgba(255, 255, 255, 1);
                // background-color: #191f27;
                border-radius: 2px;
                // padding: 24px;
                display: flex;
                flex-direction: column;

                .content_top_header {
                    height: 60px;
                    border-bottom: 1px solid #c9c9c9;
                    display: flex;
                    align-items: center;
                }

                .content_bottom_wrapper {
                    display: flex;
                    // padding: 12px 24px;
                    // height: calc(100% - 60px);
                    height: 0;
                    flex-grow: 1;
                    padding: 12px 24px;

                    .left {
                        width: 600px;
                        margin-right: 24px;
                        height: 100%;
                        padding: 12px;
                        // box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.07);
                        border: 1px solid #dadada;

                        .top_bar {
                            height: 40px;
                            display: flex;
                            align-items: center;
                            padding-bottom: 10px;
                        }

                        .ant-table-wrapper {
                            height: calc(100% - 50px);

                            .ant-spin-nested-loading {
                                height: 100%;

                                .ant-spin-container {
                                    height: 100%;

                                    .ant-table.ant-table-fixed-header {
                                        height: 100%;

                                        .ant-table-container {
                                            height: 100%;
                                            display: flex;
                                            flex-direction: column;

                                            .ant-table-header {
                                                flex-shrink: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .right {
                        flex-grow: 1;
                        padding: 12px;
                        height: 100%;
                        overflow-y: scroll;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        // box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.07);
                        border: 1px solid #dadada;
                        position: relative;

                        .chart_wrapper {
                            background-color: rgba(0, 0, 0, 0.03);
                            padding: 12px;

                            .title {
                                height: 24px;
                                line-height: 24px;
                                padding-left: 24px;
                                position: relative;

                                &::after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 8px;
                                    width: 4px;
                                    border-radius: 2px;
                                    background-color: #1890ff;
                                }

                                >span {
                                    margin-left: 12px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}