:global {
  :local(.wrapper) {
    background-color: #FFF;
    height: 100%;
    display: flex;
    flex-direction: column;

    >.bottom {
      height: 0;
      flex-grow: 1;
      background-color: rgba(247, 249, 254, 1);
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      position: relative;

      >.resize_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      >.top {
        position: relative;
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
        border-radius: 8px 8px 0px 0px;

        >.title {
          font-size: 14px;
          line-height: 20px;
          color: rgba(104, 125, 163, 1);
        }

        >.right {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
      }

      >.bottom {
        position: relative;
        margin-top: 12px;
        height: 0;
        flex-grow: 1;
        background-color: #FFF;
        padding: 15px 32px;
      }
    }

  }

}