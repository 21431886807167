:global {
    :local(.wrapper) {
        height: 100%;

        >.iframe {
            width: 100%;
            height: 100%;
            border-width: 0;
        }
    }
}