:global {
    :local(.LeftSettingPart) {
        width: 100%;
        height: 150px;
        box-sizing: border-box;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid grey;
        display: flex;

        .left {
            flex: 1;
            padding-left: 5px;
        }

        .right {
            flex: 1;
            border-left: 1px solid grey;
            padding-left: 5px;

            .sliderWrapper {
                width: 100px;
            }

            .inputWrapper {
                width: 80px;
            }

            .directionWrapper {
                display: flex;
                align-items: center;
                margin-left: 5px;

                .direction {
                    box-sizing: border-box;
                    width: 31px;
                    font-size: 20px;
                    line-height: 100%;
                    height: 31px;
                    border: 1px solid grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;
                }

                .direction:hover {
                    background-color: aliceblue;
                }

                .dirMid {
                    margin: 0px 2px;

                    .direction:first-child {
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
}