// .ant-advanced-search-form{
//   .ant-form-item {
//     display: flex;
//   }
// }
.MetaDataList.layout-container {
  background-color: #FFF;
  height: 100%;

  .pg-header {
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    line-height: 60px;
    padding: 0px 16px;
    box-sizing: border-box;

    // margin-bottom: 12px;
    &-left {
      display: flex;
    }
  }

  .layout-first {
    padding: 12px 12px;
    min-width: 800px;
    height: 100%;

    .layout-second {
      padding: 15px 32px;
      background-color: #FFF;

      .inputSearchValue {
        width: 150px;
        margin-right: 20px;
      }

      .headerOpt {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
      }
    }
  }
}