:global {
    :local(.wrapper) {
        overflow: hidden;

        >.block_1 {
            width: 1272px;
            height: 832px;
            background-image: url(./res/bck_1.svg);
            background-size: 100% 100%;
        }

        >.block_2 {
            margin-top: 40px;
            width: 1272px;
            height: 568px;
            background-image: url(./res/bck_2.svg);
            background-size: 100% 100%;
        }

        >.block_1,
        >.block_2 {
            &.left {
                float: left;
            }

            &.right {
                float: right;
            }
        }
    }
}