:global {
    :local(.content) {
        height: 700px;

        &.list {
            display: flex;
            flex-direction: column;
            position: relative;

            >.top {
                display: flex;
                justify-content: flex-end;

                >.text {
                    position: relative;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                overflow-y: scroll;

                &.scroll_bar_restyle {
                    //hardcode

                    //滚动条背景
                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    // //滚动条前景
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba($color:#C9C9C9, $alpha: 1);
                        border-radius: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        border-radius: 2px;
                    }
                }
            }

        }
    }
}