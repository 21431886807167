:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.tabs_wrapper {
            padding: 0 24px;
            display: flex;
            align-items: center;

            .ant-breadcrumb {
                margin-right: 47px;
            }

            >.right {
                margin-left: auto;
            }
        }

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
        }
    }
}