:global {
  :local(.mixed) {
    height: 100%;
    overflow-y: auto;
    .mixed-header {
      height: 60px;
      background: #FFFFFF;
      display: flex;
      justify-content: space-between;
      line-height: 60px;
      padding: 0px 16px ;
      box-sizing: border-box;
      // margin-bottom: 12px;
      &-left {
        display: flex;
      }
    }
    .mixed-container {
      padding: 20px;
      box-sizing: border-box;
      &-content {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 20px;
        box-sizing: border-box;
        .header {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          &-left {
            display: flex;
          }
          &-right {

          }
        }
        .list {
          .more {
            position: absolute;
            top: 0;
            right: 0;
            width: 80px;
            padding: 8px;
            background-color: #FFFFFF;
            border-radius: 4px;
            z-index: 100;
            height: 60px;
            li {
              list-style: none;
            }
          }
        }
      }
    }
  }
}
