:global {
    :local(.wrapper) {

        >.item {

            &:nth-child(n+2) {
                margin-top: 12px;
            }

            >.top {
                height: 32px;
                padding-left: 24px;
                line-height: 32px;
            }

            >.bottom {
                height: 200px;
                background-color: rgba(0, 0, 0, 0.03);
            }
        }
    }
}