:global {
    :local(.eventForm) {
        display: flex;
        flex-direction: column;
        padding: 0 24px 24px;
        height: 100%;
        background-color: #EDEEF1;

        .content-wrap {
            position: relative;
            height: 0;
            flex-grow: 1;
            border-radius: 4px;
            background-color: #fff;

            .title {
                height: 56px;
                line-height: 56px;
                border-bottom: 1px solid #c9c9c9;
                background: url('../images/enter@2x.png') no-repeat 20px;
                background-size: 24px;
                text-indent: 48px;
                font-size: 16px;
                color: #357CF7;
            }

            .scroll-wrap {
                height: calc(100% - 56px);
                padding: 24px;
                overflow: auto;

                &>div {
                    width: 50%;
                    min-width: 872px;
                }

                .form-wrap {
                    padding: 16px;
                    background-color: #F5F7FA;
                    border-radius: 4px;

                    .creator-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 18px;

                        &>span {
                            color: #2B3249;
                            font-size: 16px;
                            font-weight: 500;

                            span {
                                color: #999;
                                font-size: 12px;
                            }
                        }

                        .reset-icon {
                            display: inline-block;
                            text-indent: 24px;
                            line-height: 24px;
                            height: 24px;
                            font-size: 12px;
                            color: #1890FF;
                            background: url('../images/reset@2x.png') no-repeat left;
                            background-size: 24px;
                            cursor: pointer;
                        }
                    }
                }

                .footer {
                    height: 32px;
                    text-align: right;
                    margin-top: 40px;

                    .ant-btn-primary {
                        background-color: #375B98;
                        border-color: #375B98;
                        color: #fff;
                        &:hover {
                            opacity: .8;
                        }
                    }
                }
            }
        }
    }
}