:global {
    :local(.wrapper) {
        display: flex;
        align-items: center;

        >.date {
            position: relative;
            height: 32px;
            display: flex;
            align-items: center;

            >.text {
                font-size: 14px;
                color: #546677;
                line-height: 14px;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}