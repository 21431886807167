:global {
  :local(.ironData) {
    padding: 0 20px;
    box-sizing: border-box;
    .ironData-header {
      display: flex;
      justify-content: space-between;
      &-left {

      }
      &-right {

      }
    }
  }
}
