:global {
    :local(.wrapper) {
        padding-left: 24px;
        padding-right: 24px;

        >.bread_wrapper {
            height: 40px;
        }

        >.tabs_wrapper {
            display: flex;
            align-items: center;

            >.right {
                margin-left: auto;
                display: flex;
                align-items: center;

                >.item {
                    border: 1px solid rgba(128, 128, 128, 0.226);
                    background-color: #FFFFFF;
                    width: 80px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.custom {
                        position: relative;
                    }

                    &.selected {
                        background-color: #355B99;
                        color: #FFFFFF;
                    }

                    &:nth-child(n+2) {
                        margin-left: 3px;
                    }

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}