:global {
    :local(.wrapper) {

        .ExamDailyScore{
            font-family: 'Microsoft Yahei';
            .toolBar{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 50px;
                >.leftTools{
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                }
                >.rightTools{

                }
            }
        }
    }
}