.rulerBar {
  position: relative;
  height: 160px;
  width: 62px;
  margin: 0 14px;
  display: inline-flex;
  justify-content: center;

  .rulerBar-bar {
    width: 32px;
    background: linear-gradient(180deg, #33C2A4 0%, #33C2A4 17%, #FEBC05 54%, #FD6161 83%, #FD6161 100%);
  }

  .ruler-left,
  .ruler-right {
    position: relative;
    height: 160px;
    width: 2px;
  }

  .ruler {
    width: 3px;
    height: 160px;
    border-bottom: 1px solid #8DA1AF;
    background-image: repeating-linear-gradient(180deg, #8DA1AF 0, #8DA1AF 1px, transparent 0, transparent 11px);
    background-repeat: no-repeat;
    background-size: 2px 100%;
    background-position: 100% 0;

    &.left {
      border-right: 1px solid #8DA1AF;
      margin-right: 2px;
    }

    &.right {
      border-left: 1px solid #8DA1AF;
      background-position: 0 100%;
      margin-left: 2px;
    }
  }

  .pointer {
    top: 0px;
    position: absolute;
    width: 100%;
    height: 10px;
    display: flex;
    justify-content: space-between;
    transform: translateY(-4px);

    &-left {
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 10px solid #fff;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }

    &-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 10px solid#fff;
    }
  }
}