:global {
    :local(.wrapper) {
        padding-left: 24px;
        padding-right: 24px;

        >.bread_wrapper {
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
}