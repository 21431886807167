:global {
    :local(.wrapper) {
        height: 100%;
        background-color: rgba(8, 27, 91, 1);
        overflow-y: hidden;
    }

    :local(.content) {
        height: 100%;
        display: flex;

        >.item {
            width: 0;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &:hover {
                opacity: 0.6;

                &::after {
                    border: 1px solid rgba(255, 255, 255, 1);
                }
            }
        }
    }
}