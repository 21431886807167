:global {
    :local(.wrapper) {

        .guide {
            display: flex;
            align-items: center;
            height: 54px;
            // justify-content: space-around;
            padding-left: 43px;
            padding-right: 24px;

            .mySpan {
                font-family: PingFangSC-Medium, PingFang SC;
                font-size: 14px;
                color: rgba(0, 0, 0, 1);
                font-weight: 500;
                line-height: 20px;

                &:not(:first-child) {
                    margin-left: 34px;
                }
            }

            strong {
                width: 56px;
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                font-family: PingFangSC-Medium, PingFang SC;
                line-height: 20px;
                color: #357CF7;
            }

            .cus_btn {
                margin-left: auto;
            }
        }

    }
}