.devicesStatus {
  width: 768px;
  height: 536px;
  background-color: #091732;
  border-radius: 16px;
  padding: 0;
  padding-top: 32px;
  font-family: 'Microsoft YaHei';

  .model-title {
    margin-left: 32px;
    font-size: 30px;
    color: #fff;
    opacity: .8;
    line-height: 42px;
  }

  &-main {
    margin: 14px 0 0 24px;
    display: flex;

    &-left {
      width: 472px;

      .rulerBar-group {
        margin-left: 78px;
      }

      ul {
        margin-top: 8px;
        padding-left: 0;
        list-style: none;

        li {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          &>div {
            width: 90px;
            text-align: center;

            &:first-child {
              width: 78px;
              font-size: 24px;
              font-weight: 400;
              line-height: 30px;
              color: #fff;
              opacity: 0.8;
              white-space: nowrap;
            }
          }

          &.tr-1 {
            font-size: 30px;
            font-weight: bold;
            line-height: 30px;
            color: #FFFFFF;
            margin-bottom: 43px;
          }

          &.tr-2 {
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            color: #FFFFFF;
            opacity: 0.8;
            margin-bottom: 11px;
          }

          &.tr-3 {
            font-size: 24px;
            font-weight: 400;
            line-height: 33px;
            color: #fff;
            opacity: 0.8;
          }
        }
      }

      &-bottom {
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #8DA1AF;
          opacity: 0.8;
          margin-right: 8px;

          &:last-child {
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 1;
          }
        }
      }
    }

    &-right {
      margin-left: 24px;
      padding-right: 30px;
      flex: 1;

      .rulerBar-group {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
      }

      ul {
        list-style: none;
        padding-left: 10px;
        margin-top: 8px;

        li {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;
          justify-content: space-between;

          &>div {
            width: 90px;
            text-align: center;
            font-size: 30px;
            line-height: 33px;
            color: #fff;
            font-weight: bold;
            &.text {
                font-size: 24px;
                line-height: 33px;
                opacity: .8;
                font-weight: 400;
            }
          }
        }
      }
    }

    .ant-divider {
      height: 400px;
      margin: 0;
      border-color: rgba(255, 255, 255, .4);
    }
  }

  .device-title {
    font-size: 28px;
    line-height: 40px;
    color: #23D5E0;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 32px;
  }
}
