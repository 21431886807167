:global {
    :local(.screenPanel) {
        background-color: #002548;
        min-width: 4224px;
        min-height: 1296px;

        // overflow-x: scroll;
        .screenPanel-header {
            width: 100%;
            height: 130px;
            background-image: url("./images/screen_header.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: space-between;

            &-left {
                font-size: 55px;
                line-height: 77px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #A6B2C2;
                padding-left: 40px;
                padding-top: 40px;
                box-sizing: border-box;
            }

            &-right {
                font-size: 31px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 44px;
                color: rgba(255, 255, 255, .8);
                padding-top: 44px;
                padding-right: 40px;
                box-sizing: border-box;

                img {
                    margin-left: 36px;
                    margin-right: 8px;
                    vertical-align: center;
                    cursor: pointer;
                }

                span {
                    vertical-align: middle;
                }
            }
        }

        .screenPanel-content {
            padding: 38px 40px 72px 40px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;

            .column-2 {
                width: 768px;
                height: 1056px;
                border-radius: 16px;
                background-color: #091732;
                &-title {
                  font-size: 30px;
                  color: #fff;
                  opacity: .8;
                  line-height: 42px;
                  margin-top: 32px;
                  text-indent: 32px;
                  margin-bottom: 18px;
                }
            }
        }
    }
}