:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 32px;

        >.top {
            height: 368px;
            display: flex;

            >.left {
                width: 624px;
                padding: 93px 0 0 56px;

                >.title {
                    font-size: 30px;
                    line-height: 42px;
                    color: rgba(63, 202, 241, 1);
                }

                >.v_wrapper {
                    display: flex;
                    align-items: flex-end;
                    color: rgba(255, 255, 255, 1);

                    >.value {
                        font-size: 100px;
                        line-height: 140px;
                    }

                    >.unit {
                        font-size: 32px;
                        line-height: 51px;
                        margin-bottom: 20px;
                        margin-left: 12px;
                    }
                }

                >.subtitle {
                    font-size: 38px;
                    line-height: 53px;
                    color: rgba(63, 202, 241, 1);
                }
            }

            >.right {
                width: 0;
                flex-grow: 1;
                padding-top: 60px;
                display: flex;
                align-items: center;

                >.left {
                    width: 240px;
                    height: 240px;

                    >.chart {
                        height: 100%;
                    }
                }

                >.right {
                    margin-left: 12px;
                    display: grid;
                    grid-template-columns: 24px 1fr 64px 60px;
                    row-gap: 12px;
                    align-items: center;
                    font-size: 24px;
                    line-height: 33px;

                    >.icon {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                    }

                    >.title {
                        color: rgba(63, 202, 241, 1);
                    }

                    >.value,
                    >.unit {
                        color: rgba(255, 255, 255, 1);
                    }

                    >.value {
                        text-align: right;
                    }

                    >.unit {
                        margin-left: 6px;
                    }
                }
            }
        }

        >.bottom {
            height: 424px;
            background-color: rgba(1, 44, 101, 1);
            display: flex;
            flex-direction: column;

            >.title {
                margin-top: 24px;
                margin-left: 24px;
                font-size: 24px;
                line-height: 33px;
                color: rgba(63, 202, 241, 1);
            }

            >.chart {
                height: 0;
                flex-grow: 1;
            }

        }
    }
}