:global {
    :local(.wrapper) {
        display: inline-block;

        >.tabs {
            display: flex;
            padding: 2px;
            background-color: rgba(246, 248, 253, 1);
            border-radius: 8px;

            >.item {
                // padding: 2px;
                cursor: pointer;
                user-select: none;

                >.text_wrapper {
                    height: 24px;
                    width: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >.text {
                        font-size: 14px;
                        color: rgba(0, 21, 41, 1);
                        line-height: 14px;
                    }

                    &.selected {
                        background-color: rgba(0, 102, 255, 1);
                        border-radius: 8px;

                        >.text {
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }
        }
    }
}