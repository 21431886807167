:global {
    :local(.DeviceDynamicPicWrapper) {
        height: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        >.resize_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        >.picRoot {
            box-sizing: border-box;
            position: relative;

            .type2 {
                cursor: pointer;
            }

            .type3 {
                cursor: pointer;
            }

            >.need_chart {
                cursor: pointer;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}