:global {
    :local(.wrapper) {
        height: 60px;
        display: flex;
        cursor: pointer;
        user-select: none;

        >.item {
            min-width: 462px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-color: #fff;
            border-radius: 0;
            background-color: #fff;
            &:first-child {
                border-style: solid;
                border-width: 2px;
                border-top-left-radius: 2px;
                border-bottom-left-radius: 2px;
                // border-radius: 16px 0 0 16px / 16px 0 0 16px;
            }

            &:nth-child(n+2) {
                border-style: solid;
                border-width: 2px 2px 2px 0;
            }

            &:last-child {
                border-top-right-radius: 2px;
                border-bottom-right-radius: 2px;
                // border-radius: 0 16px 16px 0 / 0 16px 16px 0;
            }

            >.text {
                height: 14px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #001529;
            }

            &.selected {
                border-color: #3A3A3A;
                background-color: #00676B;

                >.text {
                    color: #FFFFFF;
                }
            }
        }
    }
}
