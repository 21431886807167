:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.content_wrapper {
            margin-top: 12px;
            padding: 0 20px 24px 24px;
            height: 0;
            flex-grow: 1;
            overflow-y: scroll;

            >.content {
                background-color: white;
                padding: 12px 24px;
            }

        }
    }
}