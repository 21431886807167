:global {
  :local(.wrapper) {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 5%;
    > .top {
      height: 0;
      flex-grow: 1;
    }
    td,
    th {
      font-size: 20px;
    }
  }
  :local(.headerRow) {
    > th {
      background-color: #3e5a94 !important;
      color: #fff !important;
    }
  }
}
