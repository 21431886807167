:global {
    :local(.material-balance-wrapper) {
        .ant-input-number-handler-wrap{
            display: none;
        }
        .wrapper {
            .wrapper-title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                &-right {
                    color: #1890ff;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            &-table {
                margin-bottom: 28px;
            }
            &-smallTable {
                display: flex;
                justify-content: space-between;
                &-table {
                    width: 320px;
                    .title {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-bottom: 12px;
                        margin-top: 12px;
                    }
                }
                .form-input{
                    width: 100%;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}
