:global {
    :local(.wrapper) {
        height: 100%;

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 8px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#357CF7, $alpha: 0.3);
                border-radius: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: #E9E9E918;
                border-radius: 5px;
            }
        }

        >.top {
            height: 949px;
            display: flex;
            justify-content: space-between;

            >.item {
                width: 900px;
                background-image: url(./res/bck_1.svg);
                background-size: 100% 100%;
            }

            >.item_mid {
                width: 1848px;
            }
        }

        >.bottom {
            margin-top: 40px;
            height: 452px;
            display: flex;
            justify-content: space-between;

            >.item {
                width: 1216px;
                background-image: url(./res/bck_2.svg);
                background-size: 100% 100%;
            }
        }
    }
}