:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            display: flex;

            >.left {
                padding: 50px 0 0 56px;
                color: rgba(63, 202, 241, 1);
                font-size: 30px;
                line-height: 42px;
            }

            >.right {
                width: 0;
                flex-grow: 1;
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;

            >.chart {
                height: 100%;
            }

        }
    }
}