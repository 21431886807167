:global {
    :local(.inline-menu) {
        width: 80px;
        height: 100%;
        display: flex;
        flex-direction: column;

        &.open-animate {
            width: 200px;
            transition: all 0.5s;
            .menu-switch {
                background-image: url('../../../assets/images/v3/menu/open_state.png') !important;
                margin-left: 148px;
                transition: all 0.5s;
            }
        }

        &.close-animate {
            width: 80px;
            transition: all 0.5s;
            .menu-switch {
                margin-left: 28px;
                transition: all 0.5s;
            }
        }

        >.bottom{
            height: 0;
            flex-grow: 1;
            overflow-y: auto;
        }

        .active-style {
            color: #357CF7;
            background-color: none;
        }

        .ant-menu-submenu-title {
            height: 50px !important;
            line-height: 50px !important;
            .anticon {
                margin-right: 15px !important;
            }
        }

        .ant-menu-inline-collapsed .ant-menu-submenu-title {            
            padding: 0 calc(50% - 24px / 2) !important;          
        }

        .ant-menu-submenu-selected {

            &>.ant-menu-submenu-title {
                color: #357CF7;
            }

            .board {
                background-image: url('../../../assets/images/v3/menu/board_active.png') !important;
            }

            .target {
                background-image: url('../../../assets/images/v3/menu/target_active.png') !important;
            }

            .form {
                background-image: url('../../../assets/images/v3/menu/form_active.png') !important;
            }

            .monitor {
                background-image: url('../../../assets/images/v3/menu/monitor_active.png') !important;
            }

            .cost {
                background-image: url('../../../assets/images/v3/menu/cost_active.png') !important;
            }

            .events {
                background-image: url('../../../assets/images/v3/menu/events_active.png') !important;
            }

            .warning {
                background-image: url('../../../assets/images/v3/menu/warning_active.png') !important;
            }

            .assess {
                background-image: url('../../../assets/images/v3/menu/assess_active.png') !important;
            }

            .report {
                background-image: url('../../../assets/images/v3/menu/report_active.png') !important;
            }

            .setting {
                background-image: url('../../../assets/images/v3/menu/setting_active.png') !important;
            }

            .plus {
                background-image: url('../../../assets/images/v3/menu/plus_active.png') !important;
            }

            .elec {
                background-image: url('../../../assets/images/v3/menu/elec_active.png') !important;
            }
        }

        .ant-menu-item-selected {
            color: #357CF7 !important;
            background: none !important;
        }

        .menu-icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            vertical-align: middle;
            background-size: 24px 24px !important;
            background-repeat: no-repeat !important;

            &.menu-switch{
                cursor: pointer;
                margin-top: 20px;
                margin-bottom: 20px;
                background-image: url('../../../assets/images/v3/menu/close_state.png');
            }

            &.board {
                background: url('../../../assets/images/v3/menu/board.png');
            }

            &.target {
                background: url('../../../assets/images/v3/menu/target.png');
            }

            &.form {
                background-image: url('../../../assets/images/v3/menu/form.png');
            }

            &.monitor {
                background-image: url('../../../assets/images/v3/menu/monitor.png');
            }

            &.cost {
                background: url('../../../assets/images/v3/menu/cost.png');
            }

            &.events {
                background: url('../../../assets/images/v3/menu/events.png');
            }

            &.warning {
                background: url('../../../assets/images/v3/menu/warning.png');
            }

            &.assess {
                background: url('../../../assets/images/v3/menu/assess.png');
            }

            &.report {
                background: url('../../../assets/images/v3/menu/report.png');
            }

            &.setting {
                background: url('../../../assets/images/v3/menu/setting.png');
            }

            &.plus {
                background: url('../../../assets/images/v3/menu/plus.png');
            }

            &.elec {
                background: url('../../../assets/images/v3/menu/elec.png');
            }
        }
    }

    .ant-menu {
        .ant-menu-item-selected {
            color: #357CF7 !important;
            background: none !important;
        }
        .ant-menu-submenu-selected >.ant-menu-submenu-title{
            color: #357CF7;
        }
    }
}