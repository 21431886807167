:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.header {
            height: 40px;
            display: flex;
            align-items: center;
            overflow: hidden;
            padding-left: 24px;
        }

        >.body {
            height: 0;
            flex-grow: 1;
            padding: 0 31px 32px 24px;
            display: flex;
            flex-direction: column;

            >.bottom {
                margin-top: 16px;
                height: 0;
                flex-grow: 1;
            }
        }
    }
}