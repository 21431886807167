:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 24px;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;

            >.inner_wrapper {
                height: 100%;
                padding-bottom: 24px; // overflow auto会隐藏box shadow ???
            }
        }
    }
}