:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            padding-top: 34px;
            padding-left: 40px;
            display: flex;
            align-items: center;

            >.icon {
                width: 32px;
                height: 32px;
            }

            >.title {
                margin-left: 8px;
                font-size: 30px;
                line-height: 42px;
                color: rgba(63, 202, 241, 1);
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
        }
    }
}