:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        display: flex;
        flex-direction: column;

        >.head {
            padding: 16px 16px 0 16px;

            >.text {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #727272;
            }
        }

        >.content {
            height: 0;
            flex-grow: 1;
            padding: 24px;
            display: flex;

            >.left {
                width: 320px;

                >.content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    >.title_wrapper {
                        padding-bottom: 16px;
                        display: flex;
                        align-items: center;

                        >.text {
                            height: 16px;
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 16px;
                            color: #727272;
                        }
                    }

                    >.cards_wrapper {
                        height: 0;
                        flex-grow: 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        align-content: space-between;
                    }
                }
            }

            >.line {
                width: 1px;
                height: 100%;
                position: relative;
                margin-left: 24px;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -8px;
                    left: 0;
                    content: '';
                    background-color: #B4B4B4;
                }
            }

            >.right {
                width: 0;
                flex-grow: 1;
                margin-left: 16px;
                display: flex;
                flex-direction: column;

                >.title {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 16px;
                    color: #727272;
                }

                >.content {
                    height: 0;
                    flex-grow: 1;
                    padding-left: 8px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;

                    >.value_wrapper {
                        margin-bottom: 24px;
                        display: flex;
                        align-items: flex-end;

                        >.value {
                            height: 30px;
                            font-size: 30px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            line-height: 30px;
                            color: #00676B;
                        }

                        >.unit {
                            height: 14px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 14px;
                            color: #00676B;
                            margin-left: 4px;
                            position: relative;
                            bottom: 2px;
                        }
                    }

                    >.pic_wrapper {
                        width: 128px;
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            padding-top: 100%;
                        }
                    }
                }
            }
        }
    }
}