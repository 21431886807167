:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.card_group_row {
            height: 392px;
            display: flex;
            justify-content: space-between;

            >.left {
                width: 416px;
            }

            >.right {
                margin-left: 16px;
                width: 0;
                flex-grow: 1;
            }
        }

        >.bottom {
            margin-top: 16px;
            height: 0;
            flex-grow: 1;
        }
    }
}