:global {
    :local(.bigProductionConst) {
        width: 768px;
        height: 1056px;
        background-color: #091732;
        border-radius: 16px;
        padding: 32px;
        font-family: 'Microsoft YaHei';

        .model-title {
            font-size: 30px;
            color: #FFF;
            opacity: .8;
            line-height: 42px;
        }

        .bigProductionConst-top {
            padding: 13px 8px 0;

            .header {
                width: 100%;
                display: flex;
                margin-bottom: 12px;

                &>div {
                    font-size: 24px;
                    line-height: 33px;
                    color: #fff;
                    opacity: .6;
                    flex: 1;
                }
            }

            .row {
                margin-bottom: 30px;

                &-title {
                    display: flex;

                    &>div {
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        flex: 1;
                        font-size: 24px;
                        color: #fff;
                        opacity: 0.8;

                        &:first-child {
                            color: #23D5E0;
                            opacity: 0.8;
                        }

                        &:nth-child(2) {
                            font-size: 30px;
                            color: #fff;
                            line-height: 24px;
                            opacity: 1;
                            font-weight: bold;

                            &.up {
                                color: #FD6161;
                            }
                        }

                    }
                }

                &-process {
                    position: relative;
                    height: 12px;
                    border-radius: 8px;
                    margin-top: 8px;

                    .process-border {
                        top: 0px;
                        left: 0px;
                        content: '';
                        position: absolute;
                        display: block;
                        width: 0;
                        height: 100%;
                        border: 1px solid #8494bf;
                        border-radius: 8px;
                    }

                    &>div:first-child {
                        width: 0;
                        height: 100%;
                        padding: 2px;
                        background-color: #33c2a4;
                        background-clip: content-box;
                        border-radius: 8px 0px 0px 8px;

                        &.up {
                            background-color: #FD6161;
                        }
                    }
                }
            }

            hr {
                height: 1px;
                background-color: #8494bf;
            }

            .ant-divider {
                margin: 0;
                border-color: #fff;
                opacity: .4;
            }

            .triangle {
                display: inline-block;
                margin-bottom: 13px;

                &.up {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid #FD6161;
                }

                &.down {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 10px solid#33C2A4;
                }

                &+span {
                    text-indent: 10px;
                }
            }
        }

        .bigProductionConst-bottom {
            padding-top: 24px;
            padding-left: 20px;
            display: flex;
            margin-left: 170px;
            position: relative;

            .part-text-left {
                position: absolute;
                left: -164px;
                top: 88px;

                &-item {
                    margin-bottom: 26px;

                    &>span {
                        display: inline-block;
                        color: #23D5E0;
                        font-size: 28px;
                        line-height: 28px;
                        opacity: 0.8;
                        margin-right: 24px;

                        &:last-child {
                            color: #fff;
                            font-size: 30px;
                            font-weight: bold;
                            line-height: 30px;
                            opacity: 1;
                            margin-right: 0;
                        }
                    }
                }
            }

            .part-text-right {
                position: absolute;
                right: 20px;
                top: 88px;

                &-item {
                    margin-bottom: 26px;

                    &>span {
                        display: inline-block;
                        color: #23D5E0;
                        font-size: 28px;
                        line-height: 28px;
                        opacity: 0.8;
                        margin-right: 24px;

                        &:last-child {
                            color: #fff;
                            font-size: 30px;
                            font-weight: bold;
                            line-height: 30px;
                            opacity: 1;
                            margin-right: 0;
                        }
                    }
                }
            }

            .partLeft,
            .partRight {
                width: 148px;
                display: flex;
                flex-direction: column;
                //justify-content: flex-end;
                //flex: 1;

                &-title {
                    font-size: 28px;
                    color: #fff;
                    opacity: .8;
                    line-height: 40px;
                    margin-bottom: 24px;
                    white-space: nowrap;
                }
            }

            .partLeft {
                padding-right: 44px;
                &-title {
                    transform: translateX(-174px);
                }
            }

            .partRight {
                padding-left: 44px;

                &-title {
                    transform: translateX(134px);
                }

                .part-text {
                    padding-left: 16px;
                }
            }

            .part-item {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-top: 12px;

                .part-text {
                    flex: 1;
                    text-align: left;
                    height: 18px;
                    white-space: nowrap;

                    &>span {
                        display: inline-block;
                        color: #23D5E0;
                        font-size: 28px;
                        line-height: 28px;
                        opacity: 0.8;
                        margin-right: 24px;

                        &:last-child {
                            color: #fff;
                            font-size: 30px;
                            font-weight: bold;
                            line-height: 30px;
                            opacity: 1;
                            margin-right: 0;
                        }
                    }
                }
            }

            .bar-item {
                display: inline-block;
                width: 104px;
            }
        }
    }
}
