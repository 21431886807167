.surviveTable {
  width: 1958px;
  height: 376px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  padding: 24px 132px;
  box-sizing: border-box;
  margin-top: 20px;
  &-title {
    font-size: 27px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #727272;
    opacity: 1;
    margin-bottom: 20px;
  }
  &-content {
    display: flex;
    .surviveTable-table {
      display:table;
      width: 1181px;
      border: 1px solid #E6E6E6;
      opacity: 1;
      border-radius: 2px;
      .pl16 {
        padding-left: 16px;
        box-sizing: border-box;
      }
      .pr16 {
        padding-right: 16px;
      }
      .left {
        //text-align: left;
        text-align: center;
        font-size: 24px;
      }
      .font20{
        font-size: 20px;
      }
      .right {
        text-align: right;
        font-size: 24px;
      }
      .center {
        text-align: center;
        font-size: 24px;
      }
      &-th {
        display:table-row;
        padding: 0 16px;
        div {
          display: table-cell;
          height: 72px;
          line-height: 72px;
          text-align: center;
          font-size: 27px;
        }
      }
      &-tr {
        display:table-row;
        .bold {
          font-size: 30px;
          font-weight: bold;
          color: #3A3A3A;
        }
        .light {
          font-size: 18px;
          color: #727272;
          margin-left: 16px;
        }
        div {
          display: table-cell;
          height: 72px;
          line-height: 72px;
          text-align: center;
          font-size: 14px;
        }
        .borderRight {
          //border-right: 1px solid #E6E6E6;
          font-size: 24px;
        }
      }
    }
    &-right {
      margin-left: 72px;
      .title {
        font-size: 27px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 27px;
        color: #3A3A3A;
        opacity: 1;
        text-align: center;
        margin-bottom: 26px;
      }
      .legend_big {
        margin-left: 50px;
        margin-top: 36px;
        .font24 {
          font-size: 24px;
        }
        .blue {
          width: 21px;
          height: 21px;
          background: #5073B8;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
        .green {
          width: 21px;
          height: 21px;
          background: #0AB39C;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
        .orange {
          width: 21px;
          height: 21px;
          background: #F16548;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}
