:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 32px;

        >.top {
            height: 368px;
            display: flex;

            >.item {
                width: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 328px;
                    padding: 93px 0 0 56px;

                    >.title {
                        font-size: 30px;
                        line-height: 42px;
                        color: rgba(63, 202, 241, 1);
                    }

                    >.v_wrapper {
                        margin-top: 24px;
                        display: flex;
                        align-items: flex-end;
                        color: rgba(255, 255, 255, 1);

                        >.value {
                            font-size: 60px;
                            line-height: 84px;
                        }

                    }

                    >.unit {
                        margin-top: 12px;
                        color: rgba(255, 255, 255, 1);
                        font-size: 32px;
                        line-height: 51px;
                    }
                }

                >.right {
                    width: 0;
                    flex-grow: 1;
                    padding-top: 60px;

                    >.chart {
                        height: 100%;
                    }
                }

            }

        }

        >.bottom {
            height: 424px;
            background-color: rgba(1, 44, 101, 1);
            display: flex;
            flex-direction: column;

            >.title {
                margin-top: 24px;
                margin-left: 24px;
                font-size: 24px;
                line-height: 33px;
                color: rgba(63, 202, 241, 1);
            }

            >.chart {
                height: 0;
                flex-grow: 1;
            }

        }
    }
}