:global {
    :local(.tplTree) {
        width: 260px;
        height: 100%;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
        display: flex;
        flex-direction: column;

        .head-warp {
            padding: 12px;
            border-bottom: 1px solid #c9c9c9;
            display: flex;
            justify-content: flex-end;
        }

        .tree-wrap {
            height: 0;
            flex-grow: 1;
            padding: 16px;
            overflow-y: auto;
        }
    }
}