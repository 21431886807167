:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // padding: 16px 16px 48px 16px;
        >.title {
            display: flex;
            font-size: 27px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 58px;
            color: #727272;
            //border-bottom: 1px solid #c9c9c9;
            padding: 20px 16px;
            img{
                margin-right: 8px;
            }
        }

        >.chart {
            align-self: center;
            width: 160px;
            position: relative;
            margin-bottom: 140px;
            &::before {
                content: '';
                display: block;
                padding-top: 100%;
            }

            >.chart_item {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.value_wrapper {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: flex-end;

                >.value {
                    height: 30px;
                    font-size: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 30px;
                    color: #355B99;
                }

                >.unit {
                    margin-left: 4px;
                    height: 14px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    color: #355B99;
                    position: relative;
                    bottom: 4px;
                }
            }
        }
    }
}
