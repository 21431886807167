:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            background: #FFFFFF;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
            border-radius: 16px;

            >.table_wrapper {
                margin: 24px 64px;

                >table {
                    width: 100%;

                    >thead {

                        >tr {
                            margin-bottom: 24px;
                            >th {
                                font-size: 29px;
                                color: #3a3a3a;
                                >.sort_header_wrapper {
                                    display: flex;
                                    justify-content: center;

                                    >.inner_wrapper {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        user-select: none;

                                        >.tips {
                                            margin-left: 6px;

                                            >.up {
                                                width: 0;
                                                height: 0;
                                                border-style: solid;
                                                border-width: 0 6px 12px 6px;
                                                border-color: transparent transparent #E6E6E6 transparent;

                                                &.selected {
                                                    border-color: transparent transparent #00676B transparent;
                                                }
                                            }

                                            >.down {
                                                margin-top: 2px;
                                                width: 0;
                                                height: 0;
                                                border-style: solid;
                                                border-width: 12px 6px 0 6px;
                                                border-color: #E6E6E6 transparent transparent transparent;

                                                &.selected {
                                                    border-color: #00676B transparent transparent transparent;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    >tbody {
                        color: #3a3a3a;

                        >tr {
                            >td {
                                height: 60px;
                                font-size: 24px;
                                text-align: center;

                                &.name {
                                    font-size: 24px;
                                    text-align: left;
                                }

                                >.value_wrapper {
                                    display: flex;
                                    align-items: flex-end;

                                    >.unit,
                                    >.value {
                                        width: 0;
                                        flex-grow: 1;
                                    }

                                    >.unit {

                                        height: 18px;
                                        font-size: 18px;
                                        line-height: 18px;
                                    }

                                    >.value {
                                        text-align: right;
                                        font-size: 24px;
                                        line-height: 24px;
                                        height: 24px;
                                    }

                                    >.space {
                                        width: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
