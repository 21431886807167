:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);

        >.top {
            height: 56px;
            box-shadow: 0px 4px 16px rgba(230, 234, 242, 0.6);
            display: flex;
            align-items: flex-end;
            overflow: hidden;
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left {
                background-color: rgba(245, 247, 250, 1);
                width: 288px;
                padding-top: 16px;

                >.content {
                    height: 100%;
                    padding-bottom: 16px;
                    padding-left: 16px;
                    overflow-x: hidden;
                    overflow-y: auto;

                    >.item {
                        width: 256px;
                        min-height: 48px;
                        border-radius: 8px;
                        background-color: rgba(255, 255, 255, 1);
                        padding: 14px 16px;
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(51, 51, 51, 1);
                        cursor: pointer;

                        &:nth-child(n+2) {
                            margin-top: 8px;
                        }

                        &.selected {
                            background-color: rgba(53, 124, 247, 1);
                            color: rgba(255, 255, 255, 1);
                        }

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            >.right {
                width: 0;
                flex-grow: 1;
                padding-left: 16px;
                padding-top: 16px;
            }
        }

    }
}