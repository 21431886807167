:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.top {
            display: flex;
            align-items: center;
            padding: 12px 0;
            justify-content: space-between;
        }

        >.bottom {
            height: 0;
            flex-grow: 1;

            >.inner_wrapper {
                width: 100%;
                height: 100%;
                overflow: auto;

                &.scroll_bar_restyle {

                    //hardcode
                    //滚动条背景
                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    // //滚动条前景
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba($color:#C9C9C9, $alpha: 1);
                        border-radius: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        border-radius: 2px;
                    }
                }

                >table {
                    min-width: 100%;
                    border-collapse: separate;
                    border-spacing: 0;

                    >thead {
                        >tr {
                            >th {
                                position: sticky;
                                top: 0;
                                background-color: #F4F5F7;
                                padding: 10px 0;
                                text-align: center;
                                z-index: 2;

                                &:first-child {
                                    left: 0;
                                    z-index: 10;
                                }

                                &:last-child {
                                    right: 0;
                                    z-index: 10;
                                }

                                &:nth-last-child(2) {
                                    right: 130px;
                                    z-index: 10;
                                }

                                &:nth-child(2) {
                                    left: 60px;
                                    z-index: 10;
                                }

                                &.silo {
                                    width: 60px;
                                }

                                &.name,
                                &.weight {
                                    width: 130px;
                                }

                                &.action {
                                    width: 180px;
                                }

                                // &.add {
                                //     width: 800px;
                                // }

                            }
                        }
                    }

                    >tbody {
                        >tr {

                            &:nth-child(odd) {
                                >td {
                                    background-color: #F4F5F7;
                                }
                            }

                            &:nth-child(even) {
                                >td {
                                    background-color: #FAFAFA;
                                }
                            }

                            &:last-child {
                                >td {
                                    // background-color: #F4F5F7;
                                    position: sticky;
                                    bottom: 0;
                                    border-bottom-width: 1px;
                                    border-bottom-color: #d5d5d5;
                                }
                            }

                            &.data {
                                >td {
                                    &:nth-child(2) {
                                        // background-color: #F4F5F7;
                                        position: sticky;
                                        left: 60px;
                                    }
                                }
                            }

                            >td {
                                padding: 10px 0;
                                text-align: center;
                                border-style: solid;
                                border-width: 1.4px 0 0 1.4px;
                                border-color: #d5d5d5 #d5d5d5 transparent;

                                &:first-child {
                                    position: sticky;
                                    left: 0;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                }

                                &:last-child {
                                    position: sticky;
                                    right: 0;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                    border-right-width: 1px;
                                    border-right-color: #d5d5d5;
                                }

                                &:nth-last-child(2) {
                                    position: sticky;
                                    right: 130px;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}