:global {
    :local(.wrapper) {
        height: 100%;

        .card_hide {
            opacity: 0;
        }

        >.gpA_wrapper {
            position: absolute;
            right: 24px;
            bottom: 40px;
            display: flex;
            align-items: flex-end;

            >.left {
                display: flex;
                flex-direction: row-reverse;
            }

            >.right {
                margin-left: 8px;

                >.title {
                    height: 19px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 19px;
                    color: #A6B2C2;
                    opacity: 0.8;
                }
            }
        }

        >.gpB_wrapper {
            position: absolute;
            top: 72px;
            left: 32px;

            >.title {
                height: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 19px;
                color: #A6B2C2;
                opacity: 0.8;
            }

            >.content {
                margin-top: 8px;
                display: flex;
            }
        }
    }
}