:global {
    :local(.wrapper) {
        height: 100%;

        .card_hide {
            opacity: 0;
        }

        >.gp_wrapper {
            position: absolute;

            >.title {
                height: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 19px;
                color: #A6B2C2;
                opacity: 0.8;
            }

            >.content {
                display: flex;
            }

            &.gpC {
                top: 72px;
                right: 24px;
                display: flex;
                align-items: flex-start;

                >.content {
                    margin-left: 8px;
                }
            }

            &.gpD {
                left: 24px;
                bottom: 24px;

                >.content {
                    margin-top: 8px;
                }
            }
        }
    }
}