:global {
    :local(.wrapper) {
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        align-items: center;

        >.tabs_wrapper {
            margin-left: 24px;
            display: flex;
            align-items: center;
        }

        >.right {
            margin-left: auto;
        }
    }
}