:global {
    :local(.wrapper) {
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        >.top {
            position: relative;
            height: 56px;
            padding-left: 16px;
            display: flex;
            align-items: center;
            color: rgba(53, 124, 247, 1);
            font-size: 16px;
            line-height: 16px;

            &::after {
                position: absolute;
                content: "";
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: rgba(201, 201, 201, 1);
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
        }
    }
}