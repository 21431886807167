:global {
    :local(.reportWrapper) {
        $template-color: #375B98;

        .ant-modal {
            width: auto;
        }

        .ant-modal-body {
            min-height: 456px;
            padding: 56px 16px 0;
            border-radius: 4px 4px 0 0;
        }

        .toolbar {
            position: absolute;
            top: 0;
            left: 0;
            height: 56px;
            line-height: 56px;
            width: 100%;
            display: flex;
            align-items: center;
            background-color: #E2E4E7;
            padding-left: 20px;
            border-radius: 4px 4px 0 0;

            .date-info {
                min-width: 100px;
                margin-right: 10px;
            }

            .shift-btn {
                display: flex;
                align-items: center;

                i {
                    width: 34px;
                    height: 34px;
                    background-color: #fff;
                    border-radius: 4px;
                    display: inline-block;
                    cursor: pointer;
                }
                span{
                    color: #000;
                    line-height: 34px;
                    padding: 0 16px;
                    background-color: #fff;
                    border-radius: 4px;
                }
            }

            .right-arrow {
                background: url('../../../../../../../assets/images/right_arrow.png') no-repeat center;
                margin-left: 9px;
            }

            .left-arrow {
                background: url('../../../../../../../assets/images/left_arrow.png') no-repeat center;
                margin-right: 9px;
            }
            &::before {
                position: absolute;
                left: 0;
                content: '';
                display: inline-block;
                border-top: 4px solid transparent;
                border-left: 4px solid #375B98;
                border-bottom: 4px solid transparent;
                height: 24px;
                width: 4px;
            }
        }

        .full-screen-btn {
            position: absolute;
            top: 0;
            display: block;
            right: 56px;
            line-height: 56px;
            cursor: pointer;
        }

        .title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            line-height: 56px;
        }

        .reportForm {
            min-height: 360px;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: auto hidden;

            .table-head {
                display: flex;
                align-items: center;
                display: -webkit-box;

                &>div {
                    position: relative;
                    flex: 1;
                    font-family: 'Source Han Sans CN';
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                    min-height: 40px;
                    padding: 8px 10px;
                    width: 137px;
                    min-width: 137px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: pre-wrap;
                    word-break: break-all; 
                    background-color: $template-color;                   

                    &:first-child {
                        width: 90px;
                        min-width: 90px;
                        max-width: 90px;
                        border-top-left-radius: 4px;
                        border-left: 1px solid $template-color;
                    }

                    &:last-child {
                        border-top-right-radius: 4px;
                        border-right: 1px solid $template-color;
                    }
                    &:not(:first-child){
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 2px;
                            height: 16px;
                            border-left: 1px solid #fff;
                        }
                    }
                }
            }

            .table-row {
                flex: 1;

                .row-item {
                    width: 100%;
                    height: 40px;
                    display: inline-flex;
                    margin-bottom: 0px;

                    &>div {
                        flex: 1;
                        color: #3A3A3A;
                        font-size: 12px;
                        width: 137px;
                        min-width: 137px;
                        padding: 0 10px;
                        display: inline-flex;
                        line-height: 32px;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 0;
                        // border-bottom: 1px dashed #AFDAD8;

                        &:first-child {
                            width: 90px;
                            min-width: 90px;
                            max-width: 90px;
                            border-left: 1px solid $template-color;
                        }
                        &:last-child {
                            border-right: 1px solid $template-color;
                        }
                    }

                    &:last-child {
                        border-radius: 0 0 4px 4px;                        

                        &>div {
                            border-bottom: 1px solid $template-color;
                            &:first-child {
                                border-bottom-left-radius: 4px;
                            }
                            &:last-child {
                                border-bottom-right-radius: 4px;
                            }
                        }
                    }
                }

            }

            .highlight-row>div {
                background-color: rgba(24, 144, 255, .05);
                input.valid{
                    background-color: #fff !important; 
                }
            }

            .ant-form-item {
                position: relative;

                input {
                    border-color: #F5F5F5;
                    height: 24px;
                    border-radius: 4px;

                    &:focus {
                        // box-shadow: 0 0 0 2px rgba(0, 103, 107, 0.2);
                        border: 1px solid #1890FF;
                    }
                    &.ignore{
                        background-color: #fff;
                        border-color: #E8E8E8;
                    }
                    &.empty{
                        background-color: #D0E7FF;
                    }
                    &.valid{
                        background-color: #F5F5F5;
                    }
                }

                .error-msg {
                    visibility: hidden;
                    position: absolute;
                    font-size: 12px;
                    line-height: 14px;
                    color: red;
                    white-space: nowrap;

                    &.is-error {
                        visibility: visible;
                    }
                }
            }
        }

        .ant-modal-footer {
            border-top: none;
            padding: 16px;

            button {
                border-radius: 4px;

                &:not(.ant-btn-primary) {
                    float: right;
                    margin-left: 16px;
                }

                &:hover {
                    color: $template-color;
                    border-color: $template-color;
                }

                &.ant-btn-primary {
                    background-color: $template-color;
                    border-color: $template-color;

                    &:hover {
                        color: #fff;
                        background-color: rgba(0, 103, 107, 0.8);
                        border-color: rgba(0, 103, 107, 0.8);
                    }
                }
            }
        }
    }
}