:global {
    :local(.productionTarget) {
        background-color: #091732;
        border-radius: 16px;
        padding: 32px 40px 40px;
        width: 768px;
        height: 1056px;
        display: flex;
        flex-direction: column;

        .model-title {
            font-size: 30px;
            line-height: 42px;
            color: rgba(255, 255, 255, .8);
        }

        .productionTarget-header {
            display: flex;
            color: #A6B2C2;
            margin-bottom: 16px;
            margin-top: 40px;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #fff;

            &-right {
                width: 248px;
                font-size: 22px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #fff;
                opacity: 0.8;
            }
        }

        .productionTarget-list {
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .productionTarget-item {
                display: flex;
                align-items: flex-end;

                &-left {
                    flex: 1;

                    .left-top {
                        margin-bottom: 8px;

                        &-name {
                            font-size: 28px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 40px;
                            color: rgba(35, 213, 224, .8);
                            margin-right: 24px;
                        }

                        &-type {
                            font-size: 22px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 22px;
                            color: #fff;
                            opacity: 0.8;
                        }
                    }

                    .left-bottom {
                        padding-bottom: 4px;

                        &-number {
                            font-size: 32px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            line-height: 32px;
                            color: #FFFFFF;
                            width: 128px;
                            display: inline-block;
                        }

                        &-percent {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 24px;
                            color: #fff;
                            opacity: 0.8;
                            width: 100px;
                            display: inline-block;
                            min-width: 60px;
                            white-space: nowrap;
                        }

                        &-comparison {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            line-height: 24px;
                            color: #fff;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .triangle {
            display: inline-block;
            margin-bottom: 2px;
            margin-right: 5px;

            &.up {
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 8px solid#33C2A4;
            }

            &.down {
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 8px solid #FD6161;
            }

            &+span {
                text-indent: 10px;
            }
        }
    }
}
