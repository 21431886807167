:global {
    :local(.wrapper) {
        width: 184px;
        height: 96px;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(13, 17, 21, 0.59);
            border: 1px solid rgba(141, 161, 175, 1);
            opacity: 0.7;
            border-radius: 10px;
        }

        >.content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 13px 16px 0 16px;

            >.header {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                >.title {
                    height: 19px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 19px;
                    color: rgba(35, 213, 224, .8);
                    // opacity: 0.8;
                }

                >.standard_wrapper {
                    display: flex;
                    align-items: flex-end;

                    .title {
                        height: 19px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 19px;
                        color: #8DA1AF;
                        opacity: 0.8;
                    }

                    .value {
                        margin-left: 8px;
                        height: 19px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 19px;
                        color: #FFFFFF;
                        opacity: 0.8;
                    }
                }
            }

            >.body {
                margin-top: 8px;
                display: flex;
                align-items: center;

                >.tip_wrapper {
                    width: 18px;
                    height: 18px;
                    background: #33C2A4;
                    border-radius: 50%;
                    opacity: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >.text {
                        height: 16px;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        line-height: 16px;
                        color: #13191F;
                        opacity: 0.8;
                    }
                }

                >.value {
                    margin-left: 4px;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 24px;
                    color: #FFFFFF;
                    opacity: 1;
                }

                >.unit {
                    margin-left: 8px;
                    height: 19px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 19px;
                    color: #8DA1AF;
                    opacity: 0.8;
                }


                >.arrow_down {
                    margin-left: auto;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8px 4px 0 4px;
                    border-color: #FD6161 transparent transparent transparent;
                }
            }

            >.foot {
                margin-top: 4px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                >.value {
                    height: 16px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 16px;
                    color: #FFFFFF;
                }

                >.unit {
                    margin-left: 4px;
                    height: 16px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 16px;
                    color: #8DA1AF;
                    opacity: 0.8;
                }
            }
        }
    }
}