:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        background: #FFFFFF;
        padding: 16px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        th {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        >.tab_row {
            display: flex;

            .tab_item {
                height: 24px;
                user-select: none;
                cursor: pointer;

                &:nth-child(n+2) {
                    margin-left: 16px;
                }

                .text {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 16px;
                    color: #3A3A3A;
                }

                &.selected_tab_item {
                    position: relative;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        content: '';
                        height: 3px;
                        background-color: #00676B;
                    }
                }
            }

            .switch_btn_wrapper {
                width: 120px;
                height: 24px;
                border-radius: 12px;
                user-select: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 2px;

                .text {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 16px;
                    color: #3A3A3A;
                }

                .ball {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #FFFFFF;
                }
            }
        }

        >.content_wrapper {
            margin-top: 24px;
            height: 0;
            flex-grow: 1;
            position: relative;

            >.resize-wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.content {
                position: relative;
                height: 100%;
            }
        }
    }
}