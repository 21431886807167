:global {
    :local(.wrapper) {
        height: 100%;
        background-color: rgba(247, 249, 254, 1);
        position: relative;
        padding: 24px;
    }

    :local(.content) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.top {
            height: 56px;
            display: flex;
            align-items: center;
            padding: 0 24px;
            background-color: rgba(255, 255, 255, 1);
            box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
            border-radius: 8px 8px 0px 0px;

            >.title {
                font-size: 14px;
                line-height: 20px;
                color: rgba(51, 51, 51, 1);
            }

            >.right {
                margin-left: auto;
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
        }
    }
}