:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.content_wrapper {
            margin-top: 12px;
            padding: 0 20px 24px 24px;
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left {
                width: 0;
                flex-grow: 2;
                display: flex;
                flex-direction: column;

                >.top {
                    height: 200px;
                    display: flex;
                    flex-direction: column;

                    >.bottom {
                        height: 0;
                        flex-grow: 1;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        >.item {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            >.top {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                border: 1px solid rgba(0, 0, 0, 0.3);

                                &.green {
                                    background-color: rgb(2, 199, 2);
                                }

                                &.yellow {
                                    background-color: yellow;
                                }

                                &.red {
                                    background-color: red;
                                }

                                &.grey {
                                    background-color: grey;
                                }
                            }

                            >.bottom {
                                margin-top: 6px;
                                height: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }

                >.bottom {
                    margin-top: 12px;
                    height: 0;
                    flex-grow: 1;

                    >.bottom {
                        padding: 0 0 0 24px;
                        margin-top: 12px;
                        height: 0;
                        flex-grow: 1;
                        overflow-y: auto;
                        overflow-x: hidden;

                        >.item {
                            &:nth-child(n+2) {
                                margin-top: 12px;
                            }

                            >.top {
                                font-weight: bold;
                            }

                            >.bottom {
                                margin-top: 6px;
                                height: 150px;
                            }
                        }

                    }
                }
            }

            >.right {
                margin-left: 12px;
                width: 0;
                flex-grow: 3;

                >.top {
                    margin-top: 12px;
                    padding-right: 24px;
                    display: flex;
                    justify-content: flex-end;

                    >.wrapper {
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 2px;
                    }
                }

                >.bottom {
                    margin-top: 12px;
                    height: 0;
                    flex-grow: 1;
                    overflow-y: scroll;
                    padding: 0 24px;
                    overflow-x: hidden;
                }
            }

        }
    }

    :local(.block) {
        background-color: white;
        padding: 12px 0;
        display: flex;
        flex-direction: column;

        >.title {
            padding-left: 24px;
            font-weight: bold;
            font-size: 16px;
        }
    }
}