:global {
    :local(.wrapper) {
        height: 100%;
        padding: 25px 0 30px 40px;

        >.content_wrapper {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            >.content {
                width: 820px;

                >.item {
                    >.top {
                        height: 48px;
                        background-color: rgba(12, 73, 123, 1);
                        display: flex;
                        align-items: center;
                        padding-left: 24px;

                        >.title {
                            min-width: 178px;
                            font-size: 20px;
                            line-height: 28px;
                            color: rgba(255, 255, 255, 1);
                        }

                        >.item {
                            min-width: 249px;
                            display: flex;
                            align-items: center;

                            >.bar {
                                width: 24px;
                                height: 8px;
                                border-radius: 2px;
                            }

                            >.name {
                                margin-left: 8px;
                                font-size: 20px;
                                line-height: 28px;
                                color: rgba(255, 255, 255, 1);
                            }

                            >.value {
                                margin-left: 8px;
                                font-size: 26px;
                                line-height: 37px;
                            }

                            >.unit {
                                margin-left: 4px;
                                font-size: 20px;
                                line-height: 28px;
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                    }

                    >.bottom {
                        height: 120px;
                        display: flex;

                        >.left {
                            width: 0;
                            flex-grow: 1;
                            font-size: 18px;
                            line-height: 25px;
                            color: rgba(77, 193, 215, 1);
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            padding-bottom: 21px;
                        }

                        >.right {
                            width: 642px;
                        }
                    }
                }
            }
        }
    }
}