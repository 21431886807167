:global {
  :local(.ironSettings) {
    padding: 0 20px;
    box-sizing: border-box;
    .iron-chart {
      width: 1000px;
      background: #FFFFFF;
      border-radius: 10px;
      margin: 0 auto;
      padding: 20px;
    }
    .iron-settings {
      width: 1000px;
      background: #FFFFFF;
      border-radius: 10px;
      margin: 18px auto;
      padding: 20px;
      .data-settings {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
