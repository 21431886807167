:global {
    :local(.wrapper) {
        width: 184px;
        height: 120px;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(13, 17, 21, 0.59);
            border: 1px solid rgba(141, 161, 175, 1);
            opacity: 0.7;
            border-radius: 10px;
        }

        >.content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 13px 16px 0 16px;

            >.title {
                height: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 19px;
                color: rgba(35, 213, 224, .8);
                // opacity: 0.8;
            }

            >table {
                margin-top: 4px;
                width: 100%;

                >tbody {
                    >tr {
                        >td {
                            padding: 0;

                            >.value_wrapper {
                                height: 16px;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                line-height: 16px;
                                color: #FFFFFF;
                            }

                            >.unit_wrapper {
                                // padding-left: 8px;
                                height: 16px;
                                font-size: 12px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                line-height: 16px;
                                color: #8DA1AF;
                                opacity: 0.8;
                            }

                            >.arrow_wrapper {
                                height: 16px;
                                display: flex;
                                align-items: flex-end;
                                padding-bottom: 4px;

                                >.arrow_top {
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 0 4px 8px 4px;
                                    border-color: transparent transparent #33C2A4 transparent;
                                }

                                >.arrow_down {
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 8px 4px 0 4px;
                                    border-color: #FD6161 transparent transparent transparent;
                                }
                            }

                            >.percent_wrapper {
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;

                                >.text {
                                    height: 16px;
                                    font-size: 12px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    line-height: 16px;
                                    color: #8DA1AF;
                                    opacity: 0.8;
                                }
                            }
                        }

                        &:first-child {
                            .r1_value_wrapper {
                                height: 24px;
                                display: flex;
                                align-items: flex-end;

                                >.value {
                                    height: 24px;
                                    font-size: 18px;
                                    font-family: Microsoft YaHei;
                                    font-weight: bold;
                                    line-height: 24px;
                                    color: #FFFFFF;
                                    // hard code // later fix card_1
                                    max-width: 55px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                >.space {
                                    width: 0;
                                    flex-grow: 1;
                                    max-width: 10px;
                                }

                                >.unit {
                                    max-width: 60px;
                                    height: 19px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    line-height: 19px;
                                    color: #8DA1AF;
                                    opacity: 0.8;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }

                            .arrow_wrapper {
                                height: 24px;
                            }

                            .percent_wrapper {
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}