:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 16px;
        display: flex;
        flex-direction: column;

        >.header {
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;

            >.content {
                display: flex;
                align-items: center;

                >.title {
                    height: 25px;
                    font-size: 18px;
                    line-height: 25px;
                    color: rgba(255, 255, 255, 1);
                }

                >.bars_wrapper {
                    margin-left: 9px;
                    width: 396px;
                    display: flex;
                    align-items: center;
                    height: 10px;

                    >.bar {
                        width: 0;
                        flex-grow: 0;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 6px;
                        position: relative;

                        >.text {
                            position: absolute;
                            bottom: -8px;
                            left: 50%;
                            transform: translate(-50%, 100%);
                            height: 25px;
                            font-size: 18px;
                            line-height: 25px;
                            white-space: nowrap;
                        }

                        &.bar_3 {
                            flex-grow: 112;
                            background-color: #1890FF;
                            color: #1890FF;
                        }

                        &.bar_2 {
                            flex-grow: 60;
                            background-color: #FFBE0D;
                            color: #FFBE0D;
                        }

                        &.bar_1 {
                            flex-grow: 32;
                            background-color: #FB6060;
                            color: #FB6060;
                        }
                    }
                }

                >.value {
                    margin-left: 10px;
                    font-size: 26px;
                    line-height: 26px;
                    height: 26px;
                    color: #FB6060;
                }
            }
        }

        >.content {
            margin-top: 46px;
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left {
                width: 0;
                flex-grow: 178;
                display: flex;
                flex-direction: column;

                >.title {
                    margin-top: 2px;
                    font-size: 20px;
                    line-height: 28px;
                    height: 28px;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                }

                >.chart_wrapper {
                    height: 0;
                    flex-grow: 1;
                    margin-top: 6px;
                    position: relative;
                    // overflow: hidden;

                    >.resize {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }

                    >.chart {
                        position: relative;
                        height: 100%;
                    }
                }

            }

            >.right {
                width: 0;
                flex-grow: 242;
                display: flex;
                flex-direction: column;
                align-items: center;

                >.title {
                    margin-top: 2px;
                    font-size: 20px;
                    line-height: 28px;
                    height: 28px;
                    color: rgba(255, 255, 255, 1);
                    text-align: center;
                }

                >.rank_wrapper {
                    margin-top: 15px;
                    width: 100%;

                    >.item {
                        height: 40px;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        padding-left: 8px;
                        padding-right: 12px;
                        font-size: 18px;
                        line-height: 18px;
                        color: rgba(255, 255, 255, 1);

                        >.text {
                            height: 18px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        >.rate {
                            margin-left: auto;
                            height: 18px;
                            white-space: nowrap;
                        }

                        &.odd {
                            background-color: rgba(12, 73, 123, 0.4);
                        }
                    }
                }
            }
        }
    }
}