:global {
    :local(.wrapper) {
        height: 64px;
        display: flex;
        justify-content: space-between;
        background-color: #355B99;

        >.left {
            display: flex;
            align-items: center;
            padding-left: 43px;

            .divide_line {
                width: 1px;
                height: 28px;
                background: #C4D3EC;
                opacity: 0.45;
                margin: 0 16px;
            }

            .line {
                width: 1px;
                height: 12px;
                background: #C4D3EC;
                margin-left: 12px;
                margin-right: 8px;
                margin-top: 5px;
                opacity: 0.45;
            }

            .text {
                margin-left: 0px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #C4D3EC;
                margin-top: 5px;
            }
        }

        >.right {
            display: flex;
            align-items: center;
            padding-right: 23px;

            .avatar {
                width: 24px;
                height: 24px;
            }

            .user_name {
                margin-left: 8px;
                height: 14px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 14px;
            }

            .ant-dropdown-trigger {
                display: flex;
                align-items: center;
                cursor: pointer;

                .user_name {
                    height: auto;
                    line-height: 24px;
                }

                .anticon-down {
                    color: #fff;
                    margin-left: 5px;
                }

            }
        }
    }

    :local(.headerDorpDown) {
        z-index: 10;

        &.ant-dropdown {
            min-width: 128px !important;

            .ant-dropdown-menu-item {
                line-height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #666;
                user-select: none;

                .icon {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    font-style: normal;

                    &.logout-icon {
                        background: url('./res/logout.svg') no-repeat;
                    }

                    &.personal-icon {
                        background: url('./res/personal.svg') no-repeat;

                        &.active {
                            background: url('./res/personal_active.svg') no-repeat;
                        }
                    }
                }

                &:hover,
                &.active {
                    color: #357CF7;

                    .personal-icon {
                        background: url('./res/personal_active.svg') no-repeat !important;
                    }

                    .logout-icon {
                        background: url('./res/logout_active.svg') no-repeat !important;
                    }
                }
            }
        }

    }
}