:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.top {
            display: flex;
            align-items: center;
            padding: 12px 0;
            justify-content: space-between;
        }

        >.bottom {
            height: 0;
            flex-grow: 1;

            >.inner_wrapper {
                width: 100%;
                height: 100%;
                overflow: auto;

                & .scroll_bar_restyle {
                    //hardcode

                    //滚动条背景
                    &::-webkit-scrollbar {
                        width: 8px;
                        background-color: rgba(0, 0, 0, 0);
                    }

                    // //滚动条前景
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba($color:#357CF7, $alpha: 1);
                        border-radius: 5px;
                    }

                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                        background-color: #E9E9E9;
                        border-radius: 5px;
                    }
                }

                >table {
                    min-width: 100%;
                    border-collapse: separate;
                    border-spacing: 0;

                    >thead {
                        >tr {
                            >th {
                                position: sticky;
                                top: 0;
                                background-color: #F4F5F7;
                                padding: 10px 0;
                                text-align: center;
                                z-index: 2;

                                &:first-child {
                                    left: 0;
                                    z-index: 10;
                                }

                                &:last-child {
                                    right: 0;
                                    z-index: 10;
                                }

                                &:nth-last-child(2) {
                                    right: 130px;
                                    z-index: 10;
                                }

                                &:nth-child(2) {
                                    left: 60px;
                                    z-index: 10;
                                }

                                &.silo {
                                    width: 60px;
                                }

                                &.name,
                                &.weight {
                                    width: 130px;
                                }

                                &.action {
                                    width: 180px;

                                    >.tool {
                                        display: none;
                                    }

                                    &:hover {

                                        >.tool {
                                            background-color: rgba(255, 255, 255, 0.8);
                                            display: flex;
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            align-items: center;
                                            justify-content: space-between;
                                            font-size: 18px;
                                            color: #1890ff;

                                            >.edit {
                                                display: flex;
                                            }

                                            span {
                                                &:hover {
                                                    background-color: rgba(53, 91, 153, .15);
                                                }

                                                padding: 10px;
                                            }
                                        }
                                    }
                                }

                                // &.add {
                                //     width: 800px;
                                // }

                            }
                        }
                    }

                    >tbody {
                        >tr {

                            &:nth-child(odd) {
                                >td {
                                    background-color: #F4F5F7;
                                }
                            }

                            &:nth-child(even) {
                                >td {
                                    background-color: #FAFAFA;
                                }
                            }

                            &:last-child {
                                >td {
                                    // background-color: #F4F5F7;
                                    position: sticky;
                                    bottom: 0;
                                    border-bottom-width: 1px;
                                    border-bottom-color: #d5d5d5;
                                }
                            }

                            &.data {
                                >td {
                                    &:nth-child(2) {
                                        // background-color: #F4F5F7;
                                        position: sticky;
                                        left: 60px;
                                    }
                                }
                            }

                            >td {
                                padding: 10px 0;
                                text-align: center;
                                border-style: solid;
                                border-width: 1.4px 0 0 1.4px;
                                border-color: #d5d5d5 #d5d5d5 transparent;

                                &:first-child {
                                    position: sticky;
                                    left: 0;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                }

                                &:last-child {
                                    position: sticky;
                                    right: 0;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                    border-right-width: 1px;
                                    border-right-color: #d5d5d5;
                                }

                                &:nth-last-child(2) {
                                    position: sticky;
                                    right: 130px;
                                    // background-color: #F4F5F7;
                                    z-index: 1;
                                }

                                &.name {

                                    >.tool {
                                        display: none;
                                    }

                                    &:hover {

                                        >.tool {
                                            background-color: rgba(255, 255, 255, 0.8);
                                            display: flex;
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 18px;
                                            color: #1890ff;

                                            >div {
                                                display: flex;
                                            }

                                            span {
                                                &:hover {
                                                    background-color: rgba(53, 91, 153, .15);
                                                }

                                                padding: 10px;
                                            }
                                        }
                                    }
                                }

                                &.weight,
                                &.modify {
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.8;
                                        background-color: rgba(53, 91, 153, .15);
                                    }
                                }

                                &.total_num {
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.8;
                                        background-color: rgba(53, 91, 153, .15);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}