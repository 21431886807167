:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                padding: 10px 21px 0 21px;

                >.content {
                    position: relative;
                    padding-bottom: 28px;

                    >table {
                        width: 100%;
                        font-size: 14px;
                        line-height: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;

                        >tbody {
                            >tr {
                                >td {
                                    padding: 0;
                                    height: 34px;

                                    &.title {
                                        color: #999999;
                                        // 测试 media query
                                        // @media (max-width: 1800px) {
                                        //     color: red;
                                        // }
                                        width: 72px;
                                    }

                                    &.value {
                                        color: #333333;

                                        >.btn {
                                            color: #1890FF;
                                            margin-left: 20px;
                                            user-select: none;
                                            cursor: pointer;

                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background-color: #F0F0F0;
                    }
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                padding: 0 2px 9px 2px;
                display: flex;
                flex-direction: column;

                >.resize_wrapper {
                    height: 0;
                    overflow: hidden;
                }

                :local(.item) {
                    height: 32px;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                    font-weight: 400;
                    line-height: 12px;
                    padding-right: 20px;

                    >.td {
                        height: 12px;
                        text-align: center;

                        &.td_1 {
                            width: 0;
                            flex-grow: 155;
                        }

                        &.td_2 {
                            margin-left: 18px;
                            width: 0;
                            flex-grow: 72;

                            &.tbody {
                                display: flex;

                                >.value,
                                >.unit {
                                    width: 0;
                                    flex-grow: 1;
                                }

                                >.value {
                                    text-align: right;
                                }

                                >.unit {
                                    margin-left: 2px;
                                    text-align: left;
                                }
                            }
                        }

                        &.td_3 {
                            width: 0;
                            flex-grow: 74;
                        }

                        &.sort {
                            >.wrapper {
                                display: inline-block;
                                user-select: none;
                                cursor: pointer;

                                &:hover {
                                    opacity: 0.8;
                                }

                                >.content {
                                    display: flex;
                                    // 这里不用inline-block可以少一层嵌套
                                    // 然而这样和hover opacity冲突了
                                    // 还是需要多一层，包裹点击事件
                                    align-items: center;
                                    justify-content: center;

                                    >.text {
                                        margin-right: 4px;
                                    }

                                }
                            }
                        }
                    }
                }

                >.header {
                    height: 47px;
                    padding-top: 9px;
                    color: #999999;
                }

                >.content {
                    height: 0;
                    flex-grow: 1;
                    overflow-y: auto;
                    overflow-x: hidden; // 暂时加上

                    >.resize_wrapper {
                        height: 0;
                        overflow: hidden;
                    }

                    >.items_wrapper {
                        color: #333333;

                        >.item {
                            border-radius: 4px;
                            cursor: pointer;

                            &:nth-child(n+2) {
                                margin-top: 1px;
                            }

                            &:nth-child(even) {
                                background-color: #FFFFFF;
                            }

                            &:nth-child(odd) {
                                background-color: #F8F8F8;
                            }

                            &:not(.selected):hover {
                                opacity: 0.8;
                            }

                            &.selected {
                                background-color: #E3F2FF;
                                color: #357CF7;
                                position: relative;

                                >.close_icon {
                                    position: absolute;
                                    right: 12px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    font-size: 14px;
                                    color: #357CF7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}