.overview {
  width: 976px;
  height: 656px;
  background-color: #091732;
  margin-bottom: 30px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  .name {
    position: absolute;
    top: 32px;
    left: 32px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 42px;
    color: #fff;
    opacity: .8;
    height: auto;
  }
}