:global {
    :local(.wrapper) {
        height: 100%;
        overflow-y: auto;
        padding: 24px;
        // display: flex;
        // flex-direction: column;

        >.display_block {
            height: 400px;
            border: 1px dashed pink;
        }

        // >.edit_block {
        //     height: 0;
        //     flex-grow: 1;
        // }
    }
}