:global {
  :local(.wrapper) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    background: linear-gradient(314deg, #74BCFF 0%, #FFFFFF 100%);

    >.form_wrapper {
      flex-shrink: 0;
      width: 1472px;
      height: 792px;
      background-color: #FFFFFF;
      box-shadow: 0px 13px 35px rgba(0, 0, 0, 0.24);
      border-radius: 20px;
      display: flex;
      position: relative;

      >.all_circle_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        >.circle_1 {
          width: 31px;
          height: 31px;
          opacity: 0.6;
          background-color: #1890FF;
          border-radius: 50%;
          position: absolute;
          right: 463px;
          top: -22px;
          transform: translateY(-100%);
        }

        >.circle_2 {
          width: 71px;
          height: 71px;
          background-color: #1890FF;
          opacity: 0.6;
          border-radius: 50%;
          position: absolute;
          right: 308px;
          top: -53px;
        }

        >.circle_3 {
          width: 99px;
          height: 99px;
          background-color: #1890FF;
          opacity: 0.6;
          border-radius: 50%;
          position: absolute;
          right: 52px;
          top: 275px;
        }

        >.circle_group_wrapper {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 261px;
          height: 290px;
          overflow: hidden;
          border-bottom-right-radius: 20px;

          &::before {
            position: absolute;
            top: 20px;
            left: 20px;
            content: '';
            width: 700px;
            height: 700px;
            border-radius: 50%;
            background-color: #1890FF;
            opacity: 0.6;
          }

          &::after {
            position: absolute;
            top: 90px;
            left: 90px;
            content: '';
            width: 700px;
            height: 700px;
            border-radius: 50%;
            background-color: #FFFFFF;
          }
        }
      }

      >.left {
        width: 728px;
        background: linear-gradient(135deg, #379FFF 0%, #357CF7 100%);
        border-radius: 16px 0px 0px 16px;
        position: relative;

        >.bck_img {
          display: block;
          width: 100%;
          height: 100%;
        }

        >.slogan {
          position: absolute;
          top: 24px;
          right: 8px;
          height: 24px;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 24px;
          color: #FFFFFF;
        }

        >.logo {
          position: absolute;
          left: 24px;
          bottom: 24px;
          width: 136px;
          height: 26px;
        }
      }

      >.right {
        width: 0;
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 48px;
        padding-top: 32px;

        .content_wrapper {
          width: 376px;

          >.company_logo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 12px;

            >.divide_line {
              width: 1px;
              height: 28px;
              background: #C4D3EC;
              opacity: 0.45;
              margin: 0 16px;
            }
          }

          >.title_wrapper {
            margin-top: 11px;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            >.text_2 {
              // margin-left: 8px;
              height: 28px;
              font-size: 28px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 28px;
              color: rgba(55, 91, 152, 0.8);
            }
          }

          >.title {
            height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 18px;
            color: #6B95DD;

            &.title_1 {
              margin-top: 45px;
            }

            &.title_2 {
              margin-top: 36px;
            }
          }

          >.line {
            height: 1px;
            background: #B4B4B4;
            opacity: 0.52;
            margin-top: 3px;
          }

          >.btn_wrapper {
            margin-top: 87px;
            height: 68px;
            background: #355B99;
            border-radius: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            cursor: pointer;

            &:hover {
              // add by ql
              opacity: 0.8;
            }

            >.text {
              height: 24px;
              font-size: 24px;
              font-family: SourceHanSansCN-Medium;
              line-height: 24px;
              color: #FFFFFF;
            }
          }

        }
      }

    }

    @media screen and (max-width:1708px) {
      >.form_wrapper {
        // width: 981px;
        // height: 528px;
        zoom: 0.7;

        // >.all_circle_wrapper {
        //   zoom: 0.66;
        // }

        // >.left {
        //   width: 485px;
        // }

        // >.right {
        //   .content_wrapper {
        //     width: 320px;
        //   }
        // }

      }
    }

  }
}