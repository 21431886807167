:global {
    @mixin media-1660 {
        @media screen and (max-width: 1660px) {
            @content;
        }
    }

    body {
        @include media-1660 {
            zoom: 0.91;
        }
    }

    :local(.wrapper) {
        height: 100%;
        background-color: #EDF7F8;
        position: relative;
        .ant-pagination-item,.ant-pagination-prev,.ant-pagination-item-link, .ant-pagination-next,.ant-select:not(.ant-select-customize-input) ,.ant-select-selector{
            border-radius: 6px;
        }
        .ant-pagination-item-active{
            background: #1890FF;
            a{
                color: #fff ;
            }
        }
        .ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a {
            color: #fff;
        }
        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 4px;
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#C9C9C9, $alpha: 1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 2px;
            }
        }

        .calc-list{
            .ant-table-container .ant-table-content .ant-table-cell{
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        >.scroll_wrapper {
            height: 100%;
            overflow: auto;

            >.content {
                // 内部元素超出外部后是不管的
                height: 100%;
                min-height: 800px;
                min-width: 1660px;
                display: flex;
                flex-direction: column;

                >.bottom_wrapper {
                    height: 0;
                    flex-grow: 1;
                    display: flex;

                    >.left {
                        background-color: #001529;
                    }

                    >.right {
                        width: 0;
                        flex-grow: 1;
                        background-color: #F4F5F7;

                        >.content {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
