:global {
    :local(.wrapper) {
        // display: flex;
        // align-items: center;
        position: relative;

        >.picker_wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;

            >.hover_block {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        >.content {
            position: relative;
            display: flex;
            align-items: center;

            >.date {
                font-size: 14px;
                font-weight: 400;
                color: rgba(104, 125, 163, 1);
                line-height: 20px;
                cursor: pointer;
                user-select: none;
                margin-right: 12px;
                padding: 5px 0;

                &:hover {
                    background-color: rgba(246, 248, 253, 1);
                }
            }

        }

    }
}