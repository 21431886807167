:global {
    :local(.exam_analysis_root_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);

        .root_content_bck_wrapper {
            height: 0;
            flex-grow: 1;

            .root_content {
                height: 100%;
                display: flex;
                padding: 0 24px 24px 24px;

                >.left_content {
                    width: 700px;
                }

                >.right_content {
                    margin-left: 24px;
                    width: 0;
                    flex-grow: 1;
                }
            }
        }
    }
}