.timeSelect {
  margin-top: 29px;
  float: right;
  margin-right: 80px;
  margin-bottom: 24px;
  &-content {
    display: flex;
  }
}
.timeImg {
  display: inline-block;
  margin-right: 16px;
  margin-left: 16px;
}
.timeVal {
  width: 195px;
  height: 46px;
  border: 2px solid #3A3A3A;
  opacity: 1;
  border-radius: 16px;
  text-align: center;
  line-height: 42px;
  display: inline-block;
  font-size: 24px;
}
